import React from "react";

const Navbar = () => {
  return (
    <>
      <div id="navbar" className="navbar-area sticky-top">
        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light pb-0">
              <a className="navbar-brand">
                <img
                  src="/images/logo.png"
                  alt="logo"
                  style={{ maxWidth: "160px" }}
                />
              </a>

              <button
                className="navbar-toggler navbar-toggler-right collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item navbar-toggler">
                    <button
                      type="button"
                      className="search-icon icon-search w-100"
                    >
                      <i className="icofont-search-1"></i> Search
                    </button>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">Home</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link">About</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link">Pages</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
