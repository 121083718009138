import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useRowSelect
} from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../../reacttbl.style";
import RoyTooltip from "components/common/RoyTooltip";
import { toast } from "react-toastify";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import useQuery from "helper/useQuery";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../../util/ApiHandler";

// import { useHistory, useLocation } from "react-router-dom";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ReviewListPending = props => {
  const menuName = "ratingReview";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const LoggedinUser = props.authData.userID;

  const history = useHistory();
  let query = useQuery();
  const recordID = query.get("data");

  const referenceType = 0;
  const endpointIsApproved = null;
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const [warningAlert, setWarningAlert] = useState(false);
  const [deleteId, setDeleteID] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    getListData();
  }, []);

  // const location = useLocation();
  // const history = useHistory();

  useEffect(() => {
    if (recordID) {
      GetRecordByID(recordID);

      // // Remove query params
      // const queryParams = new URLSearchParams(location.search);
      // if (queryParams.has("data")) {
      //   queryParams.delete("data");
      //   history.replace({
      //     search: queryParams.toString()
      //   });
      // }
      // // END Remove query params
    }
  }, [recordID]);

  const GetRecordByID = async recordID => {
    const endpoint = `RatingReview/${recordID}`;
    const { data } = await API(APIMethod.GET, endpoint, null);

    handleViewModal(data);
  };

  const getListData = async () => {
    setIsLoading(true);

    const endpoint = `RatingReview/getRatingReviewData?referenceType=${referenceType}${
      endpointIsApproved != null ? "&isApproved=" + endpointIsApproved : ""
    }`;

    const { data } = await API(APIMethod.GET, endpoint, null);
    setListData(data);
    setIsLoading(false);
  };

  const ApprovedHandler = async (
    ratingReviewID,
    isApproved,
    isModalOpen = false
  ) => {
    const endpoint = "RatingReview/UpdateApproval";
    const params = {
      ratingReviewID,
      isApproved,
      CreatedByID: LoggedinUser
    };
    const { data } = await API(APIMethod.PUT, endpoint, params);
    const msg = isApproved
      ? "Review Accepted Successfully."
      : "Review Hidden Successfully.";
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT
    });
    getListData();
    if (isModalOpen) {
      handleCloseViewModal();
    }
  };

  const handleSelectedApprove = async isApproved => {
    let selectedList = selectedFlatRows.map(d => d.original);
    if (selectedList.length == 0) {
      toast.warning("Select at least one review.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      let selectedIDs = [];
      selectedList.map(e => {
        selectedIDs.push(e.ratingReviewID);
      });
      const endpoint =
        "RatingReview/UpdateSelectedPendingApproval?isApproved=" + isApproved;
      const params = selectedIDs;
      const { data } = await API(APIMethod.PUT, endpoint, params);
      const msg = isApproved
        ? "Review Accepted Successfully."
        : "Review Hidden Successfully.";
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT
      });
      getListData();
    }
  };

  const deleteClick = useCallback(
    async deletedata => {
      // Here you can view the data and delete through API calling
      var ratingReviewID = deletedata;
      const endpoint =
        "RatingReview/deleteRatings?id=" +
        ratingReviewID +
        "&userId=" +
        LoggedinUser;
      const { data } = await API(APIMethod.DELETE, endpoint, null);
      toast.success("Review Deleted Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      getListData();
    },
    [listData]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Type"
      //       />
      //     );
      //   },
      //   Filter: FilterComponent,
      //   placeholder: "",
      //   accessor: "ratingReviewType"
      // },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Reference"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "referenceName"
      },

      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Name"
      //       />
      //     );
      //   },
      //   Filter: FilterComponent,
      //   placeholder: "",
      //   accessor: "name"
      // },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Email"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "email"
      },

      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Ratings"
            />
          );
        },
        // disableSortBy: true,
        // disableFilters: true,
        Filter: FilterComponent,
        placeholder: "",
        accessor: "rating",
        Cell: tableInstance => {
          return (
            <>
              <div className="ratingReviewDiv">
                <div>{tableInstance.row.original.rating}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#FFFFFF"
                  width="0.8rem"
                  height="0.8rem"
                  viewBox="0 0 20 20"
                  aria-labelledby="icon-svg-title- icon-svg-desc-"
                  role="img"
                  className="sc-rbbb40-0 fauQLv"
                >
                  <title>star-fill</title>
                  <path d="M6.76 6.8l-6.38 0.96c-0.22 0.040-0.38 0.22-0.38 0.44 0 0.12 0.040 0.24 0.12 0.32v0l4.64 4.76-1.1 6.66c0 0.020 0 0.040 0 0.080 0 0.24 0.2 0.44 0.44 0.44 0.1 0 0.16-0.020 0.24-0.060v0l5.7-3.12 5.68 3.12c0.060 0.040 0.14 0.060 0.22 0.060 0.24 0 0.44-0.2 0.44-0.44 0-0.040 0-0.060 0-0.080v0l-1.1-6.66 4.64-4.76c0.080-0.080 0.12-0.2 0.12-0.32 0-0.22-0.16-0.4-0.36-0.44h-0.020l-6.38-0.96-2.96-6.18c-0.060-0.12-0.18-0.2-0.32-0.2s-0.26 0.080-0.32 0.2v0z"></path>
                </svg>
              </div>
            </>
          );
        }
      },

      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Review"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "review",
        Cell: tableInstance => {
          const review = tableInstance.row.original.review;
          return (
            <>
              <a
                href="javascript:void(0)"
                onClick={e => handleViewModal(tableInstance.row.original)}
              >
                {review.length > 50 ? review.substr(0, 50) + "..." : review}
              </a>
            </>
          );
        }
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        disableSortBy: true,
        disableFilters: true,
        placeholder: "",
        accessor: "isApproved",
        Cell: tableInstance => {
          const isApproved = tableInstance.row.original.isApproved;
          return (
            <>
              <div className="react-action-class wide-cell custom-action-button-royTooltip">
                {(isApproved == null || isApproved == false) && canUpdate && (
                  <RoyTooltip
                    id={"approve-" + tableInstance.row.original.ratingReviewID}
                    title={"Approve review"}
                    placement="left"
                  >
                    <div
                      id={
                        "approve-" + tableInstance.row.original.ratingReviewID
                      }
                    >
                      <button
                        className="react-table-view-button"
                        onClick={() =>
                          ApprovedHandler(
                            tableInstance.row.original.ratingReviewID,
                            true
                          )
                        }
                      >
                        <i className="fas fa-eye"></i>
                      </button>
                    </div>
                  </RoyTooltip>
                )}
                {(isApproved == null || isApproved == true) && canUpdate && (
                  <RoyTooltip
                    id={"reject-" + tableInstance.row.original.ratingReviewID}
                    title={"Hide review"}
                    placement="left"
                  >
                    <div
                      id={"reject-" + tableInstance.row.original.ratingReviewID}
                    >
                      <button
                        className="react-table-edit-button"
                        onClick={() =>
                          ApprovedHandler(
                            tableInstance.row.original.ratingReviewID,
                            false
                          )
                        }
                      >
                        <i className="fas fa-eye-slash"></i>
                      </button>
                    </div>
                  </RoyTooltip>
                )}
                {candelete && (
                  <RoyTooltip
                    id={"remove-" + tableInstance.row.original.ratingReviewID}
                    title={"Remove review"}
                    placement="left"
                  >
                    <div
                      id={"remove-" + tableInstance.row.original.ratingReviewID}
                    >
                      <button
                        className="react-table-delete-button"
                        onClick={() => {
                          setDeleteID(
                            tableInstance.row.original.ratingReviewID
                          );
                          setWarningAlert(!warningAlert);
                        }}
                      >
                        <i className="fa fa-trash-can"></i>
                      </button>
                    </div>
                  </RoyTooltip>
                )}
              </div>
            </>
          );
        }
      }
    ],
    [deleteClick]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds }
  } = useTable(
    {
      data: listData,
      columns: columns,
      initialState: {
        hiddenColumns: [!canUpdate && !candelete ? "isApproved" : ""],
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: "checkboxAction",
          width: 5,
          Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
            const modifiedOnChange = event => {
              page.forEach(row => {
                //check each row if it is not disabled
                !row.original.disabled &&
                  toggleRowSelected(row.id, event.currentTarget.checked);
              });
            };

            //Count number of selectable and selected rows in the current page
            //to determine the state of select all checkbox
            let selectableRowsInCurrentPage = 0;
            let selectedRowsInCurrentPage = 0;
            page.forEach(row => {
              row.isSelected && selectedRowsInCurrentPage++;
              !row.original.disabled && selectableRowsInCurrentPage++;
            });

            //If there are no selectable rows in the current page
            //select all checkbox will be disabled -> see page 2
            const disabled = selectableRowsInCurrentPage === 0;
            const checked =
              (isAllPageRowsSelected ||
                selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
              !disabled;

            return (
              <div className="mx-2 my-2">
                <IndeterminateCheckbox
                  onChange={modifiedOnChange}
                  checked={checked}
                  disabled={disabled}
                />
              </div>
            );
          },
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                disabled={row.original.disabled}
              />
            </div>
          )
        },
        ...columns
      ]);
    }
  );

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleViewModal = data => {
    setModalData(data);
    setShowModal(true);
  };

  const handleCloseViewModal = () => {
    setModalData(null);
    setShowModal(false);
  };

  const renderComponent = (
    <>
      {/* Common Action (Accept / Reject)*/}
      <div className="react-action-class wide-cell custom-action-button-royTooltip text-right mt-15">
        {canUpdate && (
          <RoyTooltip
            id={"approve-selected"}
            title={"Approve reviews"}
            placement="left"
          >
            <div id={"approve-selected"}>
              <button
                type="button"
                className="c-btn c-success mr-10 custom-common-action-button-royTooltip"
                onClick={() => handleSelectedApprove(true)}
              >
                <span className="">
                  <div className="fs-14 medium-text">
                    <i className="fas fa-eye"></i>
                  </div>
                </span>
              </button>
            </div>
          </RoyTooltip>
        )}
        {canUpdate && (
          <RoyTooltip
            id={"reject-selected"}
            title={"Hide reviews"}
            placement="left"
          >
            <div id={"reject-selected"}>
              <button
                type="button"
                className="c-btn c-warning custom-common-action-button-royTooltip"
                onClick={() => handleSelectedApprove(false)}
              >
                <span className="">
                  <div className="fs-14 medium-text">
                    <i className="fas fa-eye-slash"></i>
                  </div>
                </span>
              </button>
            </div>
          </RoyTooltip>
        )}
      </div>
      <ReactTableWrapper {...props}>
        <SweetAlert
          warning
          showCancel
          show={warningAlert}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => {
            setConfirmDelete(!confirmDelete);
            setWarningAlert(false);
            deleteClick(deleteId);
          }}
          onCancel={() => setWarningAlert(!warningAlert)}
        ></SweetAlert>

        <div className="table-container overflow-auto mx-0 mt-15 mb-30">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => {
                    return (
                      <td>
                        <div>
                          {header.canFilter ? header.render("Filter") : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          onPageChange={gotoPage}
          pages={pageCount}
          page={pageIndex}
        />
      </ReactTableWrapper>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        // backdrop={true}
        // className={className}
      >
        <ModalHeader toggle={() => setShowModal(!showModal)}>
          Ratings & Reviews Details
        </ModalHeader>
        <ModalBody>
          {modalData && (
            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="react-action-class wide-cell custom-action-button-royTooltip text-right">
                  {(modalData.isApproved == null ||
                    modalData.isApproved == false) &&
                    canUpdate && (
                      <RoyTooltip
                        id="approve-modal"
                        title="Approve review"
                        placement="left"
                      >
                        <div id="approve-modal">
                          <button
                            type="button"
                            className="c-btn c-success mr-10 custom-common-action-button-royTooltip"
                            onClick={() =>
                              ApprovedHandler(
                                modalData.ratingReviewID,
                                true,
                                true
                              )
                            }
                          >
                            <span className="">
                              <div className="fs-14 medium-text">
                                <i className="fas fa-eye"></i>
                              </div>
                            </span>
                          </button>
                        </div>
                      </RoyTooltip>
                    )}
                  {(modalData.isApproved == null ||
                    modalData.isApproved == true) &&
                    canUpdate && (
                      <RoyTooltip
                        id="reject-modal"
                        title="Hide review"
                        placement="left"
                      >
                        <div id="reject-modal">
                          <button
                            type="button"
                            className="c-btn c-warning custom-common-action-button-royTooltip"
                            onClick={() =>
                              ApprovedHandler(
                                modalData.ratingReviewID,
                                false,
                                true
                              )
                            }
                          >
                            <span className="">
                              <div className="fs-14 medium-text">
                                <i className="fas fa-eye-slash"></i>
                              </div>
                            </span>
                          </button>
                        </div>
                      </RoyTooltip>
                    )}
                </div>
              </div>

              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Type
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.ratingReviewType}
                </label>
              </div>
              <div className="col-sm-8 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Reference
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.referenceName}
                </label>
              </div>

              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Name
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.name}
                </label>
              </div>
              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Email
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.email}
                </label>
              </div>
              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Ratings
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  <div className="ratingReviewDiv">
                    <div>{modalData.rating}</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#FFFFFF"
                      width="0.8rem"
                      height="0.8rem"
                      viewBox="0 0 20 20"
                      aria-labelledby="icon-svg-title- icon-svg-desc-"
                      role="img"
                      className="sc-rbbb40-0 fauQLv"
                    >
                      <title>star-fill</title>
                      <path d="M6.76 6.8l-6.38 0.96c-0.22 0.040-0.38 0.22-0.38 0.44 0 0.12 0.040 0.24 0.12 0.32v0l4.64 4.76-1.1 6.66c0 0.020 0 0.040 0 0.080 0 0.24 0.2 0.44 0.44 0.44 0.1 0 0.16-0.020 0.24-0.060v0l5.7-3.12 5.68 3.12c0.060 0.040 0.14 0.060 0.22 0.060 0.24 0 0.44-0.2 0.44-0.44 0-0.040 0-0.060 0-0.080v0l-1.1-6.66 4.64-4.76c0.080-0.080 0.12-0.2 0.12-0.32 0-0.22-0.16-0.4-0.36-0.44h-0.020l-6.38-0.96-2.96-6.18c-0.060-0.12-0.18-0.2-0.32-0.2s-0.26 0.080-0.32 0.2v0z"></path>
                    </svg>
                  </div>
                </label>
              </div>

              <div className="col-sm-12 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Review
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.review}
                </label>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );

  return isLoading ? <LoadingSpinner /> : renderComponent;
};

const FilterComponent = tableInstance => {
  const { filterValue, setFilter } = tableInstance.column;
  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="tabl-search react-form-input"
      placeholder={`Search ${tableInstance.column.placeholder}`}
      onClick={e => e.stopPropagation()}
    />
  );
};

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};
export default connect(mapStateToProps)(ReviewListPending);
