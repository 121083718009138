import {
    sidebar1,
    sidebar2,
    sidebar3,
    sidebar4,
    sidebar5,
    sidebar6,
    sidebar7,
    sidebar8
} from "helper/constant";

export const sidebarTransparentData = [
    {
        image: sidebar1
    },
    {
        image: sidebar2
    },
    {
        image: sidebar3
    },
    {
        image: sidebar4
    },
    {
        image: sidebar5
    },
    {
        image: sidebar6
    },
    {
        image: sidebar7
    },
    {
        image: sidebar8
    }
];
