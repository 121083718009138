import React, { useState, useEffect } from "react";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import { compose } from "redux";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const BlogBanner = props => {
  const menuName = "blogs";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;

  const history = useHistory();

  const [blogBannerImage, setBlogBannerImage] = useState("");
  const [initialBlogBannerImage, setInitialBlogBannerImage] = useState("");
  const [blogBannerInput, setBlogBannerInput] = useState(null);

  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const key = AWSS3BucketKey.blogsBanner;

  useEffect(() => {
    if (!canUpdate) {
      history.push("/blog");
    }
    GetStaticPageData();
  }, []);

  const GetStaticPageData = async () => {
    setIsLoading(true);
    const endpoint = "Blog/getStaticPageBlogData";
    const { data } = await API(APIMethod.GET, endpoint, null);

    if (data.length > 0) {
      setBlogBannerImage(data[0].bannerImage);
      setInitialBlogBannerImage(data[0].bannerImage);
    }
    setIsLoading(false);
  };

  function handleBlogBannerImage(e) {
    if (e.target.files.length > 0) {
      setBlogBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBlogBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBlogBannerInput(null);
      setBlogBannerImage(initialBlogBannerImage);
    }
  }

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit, submitCount, errors } = props;
    if (isValid && blogBannerInput) {
      setIsLoading(true);

      const ext = blogBannerInput.name.substring(
        blogBannerInput.name.lastIndexOf(".") + 1
      );
      const uploadKey = key + (generate_uuidv4() + "." + ext);
      let bucket = AWS_SDK.bucket;

      //Remove existing file from AWS
      if (
        initialBlogBannerImage &&
        initialBlogBannerImage.includes(
          `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
        )
      ) {
        await s3DeleteFromURL(initialBlogBannerImage);
      }
      const blogBannerLocation = await UploadFile(
        blogBannerInput,
        bucket,
        uploadKey
      );
      const endpoint = "Blog/manageStaticPageBlog";
      const params = {
        bannerImage: blogBannerLocation,
        isActive
      };
      const { data } = await API(APIMethod.PUT, endpoint, params);
      toast.success("Banner Saved Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      setIsLoading(false);
      GetStaticPageData();
    }
    handleSubmit();
  };

  const renderPage = (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Blog Banner
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                <label>Blog List Banner Image</label>
                <br />
                {canUpdate && (
                  <>
                    <input
                      type="file"
                      id="fuBannerImage"
                      onChange={handleBlogBannerImage}
                      style={{ display: "none" }}
                    />
                    <label
                      className="btn btn-primary"
                      for="fuBannerImage"
                      style={{ color: "white" }}
                    >
                      <i className="fa fa-upload" />{" "}
                      {blogBannerImage ? "Change Image" : "Upload Image"}{" "}
                    </label>
                    <br />
                    <br />
                  </>
                )}

                <img src={blogBannerImage} style={{ height: "200px" }}></img>
              </div>

              <div className="form-group rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <LoadingSpinner /> : renderPage;
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

const enhancer = withFormik({
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default compose(enhancer, connect(mapStateToProps))(BlogBanner);
