import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { MiniWidget } from "components/widgets/statisticswidgets";
import API, { APIMethod } from "../../../util/ApiHandler";

import LatestActivity from "components/widgets/latestactivity/LatestActivity";

import chroma from "chroma-js";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";

const Dashboard = ({ sidebarTheme, layoutTheme }) => {
  const [facilityData, setfacility] = useState();
  const [subfacilityData, setsubfacility] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [chartLabel, setChartLabel] = useState([]);
  const [chartData, setChartdata] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [inquiryCount, setInquiryCount] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [value, setValue] = useState("Facilities");
  const [filtervalue, setfiltervalue] = useState();
  const [blogData, setBlogData] = useState([]);
  const [NewsData, setNewsData] = useState([]);

  const color = chroma("#563c91");

  const facilityName = [];
  const ratings = [];
  useEffect(() => {
    getGraphData("Facilities");
  }, []);

  const getGraphData = async type => {
    if (chartLabel.length == 0) {
      setIsLoading(true);
      const endpoint = "Dashboard/getDashboardData";
      const { data } = await API(APIMethod.GET, endpoint, null);
      setfacility(data.facilities);
      setsubfacility(data.subFacilities);
      if (type == "Facilities") {
        data.facilities.map((e, index) => {
          facilityName.push(e.facilityName);
          ratings.push(e.avgRating);
        });
      } else {
        data.subFacilities.map((e, index) => {
          facilityName.push(e.subFacilityName);
          ratings.push(e.avgRating);
        });
      }
      setChartLabel(facilityName);
      setChartdata(ratings);

      setIsLoading(false);

      setUserCount(data.dashboardCounts.users);
      setInquiryCount(data.dashboardCounts.inquiries);
      setRatingCount(data.dashboardCounts.reviews);
      setBlogData(data.blogs);
      setNewsData(data.news);
    } else {
      if (type == "Facilities") {
        facilityData.map((e, index) => {
          facilityName.push(e.facilityName);
          ratings.push(e.avgRating);
        });
      } else {
        subfacilityData.map((e, index) => {
          facilityName.push(e.subFacilityName);
          ratings.push(e.avgRating);
        });
      }
      setChartLabel(facilityName);
      setChartdata(ratings);

      setIsLoading(false);
    }
  };

  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: "Ratings",
        backgroundColor: "#6200ea",
        borderColor: "#6200ea",
        borderWidth: 1,
        hoverBackgroundColor: color.alpha(0.8).css(),
        hoverBorderColor: "#563c91",

        minBarLength: 2,

        data: chartData
      }
    ]
  };
  const handleChange = e => {
    getGraphData(e.target.value);
    setValue(e.target.value);
  };
  const handleFilterChange = e => {
    getGraphByFilter(e.target.value);
    setfiltervalue(e.target.value);
  };
  const getGraphByFilter = async filter => {
    setIsLoading(true);
    const endpoint = "Dashboard/getGraphByFilter?Filter=" + filter;
    const { data } = await API(APIMethod.GET, endpoint, null);
    setfacility(data.facilities);
    setsubfacility(data.subFacilities);
    if (value == "Facilities") {
      data.facilities.map((e, index) => {
        facilityName.push(e.facilityName);
        ratings.push(e.avgRating);
      });
    } else {
      data.subFacilities.map((e, index) => {
        facilityName.push(e.subFacilityName);
        ratings.push(e.avgRating);
      });
    }
    setChartLabel(facilityName);
    setChartdata(ratings);
    setIsLoading(false);
  };

  return (
    <div>
      <div>
        <div className="row ma-0">
          <div className="col-sm-12 ptb-15">
            <div className="roe-card-style">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div
                    style={{ textAlign: "right" }}
                    className="roe-card-header"
                  >
                    <select
                      onChange={handleChange}
                      value={value}
                      className="form-control-sm react-form-input"
                      style={{ background: "transparent" }}
                    >
                      <option>Facilities</option>
                      <option>Sub-Facilities</option>
                    </select>
                    &nbsp;&nbsp;
                    <select
                      onChange={handleFilterChange}
                      value={filtervalue}
                      className="form-control-sm react-form-input"
                      style={{ background: "transparent" }}
                    >
                      <option>This Month</option>
                      <option>Last Month</option>
                      <option>Last Three Months</option>
                      <option>Last Six Months</option>
                      <option>Last Year</option>
                    </select>
                  </div>
                  <div className="roe-card-body">
                    <Bar
                      data={data}
                      width={100}
                      height={300}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                min: 0,
                                max: 5,
                                stepSize: 1
                              }
                            }
                          ],
                          xAxes: [
                            {
                              gridLines: {
                                color: "rgba(0, 0, 0, 0)"
                              }
                            }
                          ]
                        },
                        legend: {
                          display: false
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row mlr-0" style={{ marginTop: "-15px" }}>
          <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6 ptb-15">
            <a href="/usermaster">
              <MiniWidget
                iconName="fas fa-user"
                iconColor="#6200ea"
                background="white"
                className="demo"
                headline={userCount}
                subheader="Users"
              />
            </a>
          </div>
          <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6 ptb-15">
            <a href="/inquiry">
              <MiniWidget
                iconName="fas fa-envelope"
                iconColor="#6200ea"
                background="white"
                className="demo"
                headline={inquiryCount}
                subheader="Inquiries"
              />
            </a>
          </div>
          <div className="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-6 ptb-15">
            <a href="/rating-review">
              <MiniWidget
                iconName="fas fa-star"
                iconColor="#6200ea"
                background="white"
                className="demo"
                headline={ratingCount}
                subheader="Reviews"
              />
            </a>
          </div>
        </div>

        <div className="row ma-0">
          <div className="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-12 ptb-15">
            <LatestActivity type={"blogs"} carddata={blogData} />
          </div>
          <div className="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-12 ptb-15">
            <LatestActivity type={"news"} carddata={NewsData} />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Dashboard);
