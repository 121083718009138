import React from "react";

const TopHeader = () => {
  return (
    <>
      <div className="header-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-8 col-lg-9">
              <div className="header-top-item">
                <div className="header-top-left">
                  <ul>
                    <li className="topbar-button">
                      <a>
                        <i className="icofont-ui-call"></i>
                        Call : 833-248-6271
                      </a>
                    </li>
                    <li className="topbar-button">
                      <a>
                        <i className="icofont-ui-message"></i>
                        staff@praesumhealthcare.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-lg-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
