export const sidebarData = [
  {
    name: "sidebar.dashboard",
    routepath: "/dashboard",
    iconClass: "fa-solid fa-gauge"
  },
  {
    name: "sidebar.homepage",
    iconClass: "icon fas fa-images",
    child: [
      {
        listname: "sidebar.homepagebanner",
        routepath: "/homepagebanner"
      },
      {
        listname: "sidebar.homepagestatistics",
        routepath: "/homepagestatistics"
      }
    ]
  },
  {
    name: "sidebar.categories",
    iconClass: "fa fa-clipboard-list",
    child: [
      {
        listname: "sidebar.categoryBanner",
        routepath: "/category-banner"
      },
      {
        listname: "sidebar.category",
        routepath: "/category"
      },
      {
        listname: "sidebar.subcategory",
        routepath: "/subcategory"
      },
      {
        listname: "sidebar.subsubcategory",
        routepath: "/subsubcategory"
      },
      {
        listname: "sidebar.mapcategoryfacilities",
        routepath: "/mapcategoryfacility"
      }
    ]
  },
  {
    name: "sidebar.facilities",
    iconClass: "fa fa-hospital",
    child: [
      {
        listname: "sidebar.facilityBanner",
        routepath: "/facility-banner"
      },
      {
        listname: "sidebar.facility",
        routepath: "/facility"
      },
      {
        listname: "sidebar.subfacility",
        routepath: "/subfacility"
      }
    ]
  },
  {
    name: "sidebar.blogs",
    iconClass: "fa-solid fa-blog",
    child: [
      {
        listname: "sidebar.blogBanner",
        routepath: "/blog-banner"
      },
      {
        listname: "sidebar.blog",
        routepath: "/blog"
      }
    ]
  },
  {
    name: "sidebar.news",
    iconClass: "fa-solid fa-newspaper",
    child: [
      {
        listname: "sidebar.newsBanner",
        routepath: "/news-banner"
      },
      {
        listname: "sidebar.news",
        routepath: "/News"
      }
    ]
  },
  {
    name: "sidebar.staticpages",
    iconClass: "fa-solid fa-file",
    routepath: "/staticpages"
  },
  {
    name: "sidebar.ratingReview",
    routepath: "/rating-review",
    iconClass: "icon fa fa-star"
  },
  {
    name: "sidebar.contactus",
    iconClass: "fa fa-address-book",
    child: [
      {
        listname: "sidebar.contactusedit",
        routepath: "/contactuspage"
      },
      {
        listname: "sidebar.inquiry",
        routepath: "/inquiry"
      }
    ]
  },
  {
    name: "sidebar.userManagement",
    iconClass: "fa-solid fa-users",
    child: [
      {
        listname: "sidebar.userMaster",
        routepath: "/usermaster"
      },
      {
        listname: "sidebar.userRights",
        routepath: "/userrights"
      }
    ]
  }
];
export const HorizontalSidebarData = [];
