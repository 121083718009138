export const AWSS3BucketKey = {
  homepagebanner:
    process.env.NODE_ENV === "production"
      ? "homepagebanner/"
      : "homepagebanner/",
  categoriesBanner:
    process.env.NODE_ENV === "production"
      ? "categories/banner/"
      : "categories/banner/",
  facilitiesBanner:
    process.env.NODE_ENV === "production"
      ? "facilities/banner/"
      : "facilities/banner/",
  facilities:
    process.env.NODE_ENV === "production" ? "facilities/" : "facilities/",
  blogsBanner:
    process.env.NODE_ENV === "production" ? "blogs/banner/" : "blogs/banner/",
  blogs: process.env.NODE_ENV === "production" ? "blogs/" : "blogs/",
  newsBanner:
    process.env.NODE_ENV === "production" ? "news/banner/" : "news/banner/",
  news: process.env.NODE_ENV === "production" ? "news/" : "news/",
  staticpagesBanner:
    process.env.NODE_ENV === "production"
      ? "staticpages/banner/"
      : "staticpages/banner/",
  staticpages:
    process.env.NODE_ENV === "production" ? "staticpages/" : "staticpages/"
};
