import React, { useState, useEffect } from "react";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import { compose } from "redux";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const NewsBanner = props => {
  const menuName = "news";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const CreatedByID = props.authData.userID;

  const [newsBannerImage, setNewsBannerImage] = useState("");
  const [initialNewsBannerImage, setInitialNewsBannerImage] = useState("");
  const [newsBannerInput, setNewsBannerInput] = useState(null);

  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const key = AWSS3BucketKey.newsBanner;

  useEffect(() => {
    if (!canUpdate) {
      history.push("/news");
    }
    GetStaticPageData();
  }, []);

  const GetStaticPageData = async () => {
    setIsLoading(true);
    const endpoint = "News/getStaticPageNewsData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    if (data.length > 0) {
      setNewsBannerImage(data[0].bannerImage);
      setInitialNewsBannerImage(data[0].bannerImage);
    }
    setIsLoading(false);
  };

  function handleNewsBannerImage(e) {
    if (e.target.files.length > 0) {
      setNewsBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setNewsBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setNewsBannerInput(null);
      setNewsBannerImage(initialNewsBannerImage);
    }
  }

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit, submitCount, errors } = props;
    if (isValid && newsBannerInput) {
      setIsLoading(true);

      const ext = newsBannerInput.name.substring(
        newsBannerInput.name.lastIndexOf(".") + 1
      );
      const uploadKey = key + (generate_uuidv4() + "." + ext);
      let bucket = AWS_SDK.bucket;

      //Remove existing file from AWS
      if (
        initialNewsBannerImage &&
        initialNewsBannerImage.includes(
          `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
        )
      ) {
        await s3DeleteFromURL(initialNewsBannerImage);
      }
      const newsBannerLocation = await UploadFile(
        newsBannerInput,
        bucket,
        uploadKey
      );

      const endpoint = "News/manageStaticPageNews";
      const params = {
        bannerImage: newsBannerLocation,
        isActive,
        CreatedByID
      };
      const { data } = await API(APIMethod.PUT, endpoint, params);
      toast.success("Banner Saved Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      setIsLoading(false);
      GetStaticPageData();
    }
    handleSubmit();
  };

  const renderPage = (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>News Banner
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                <label>News List Banner Image</label>
                <br />
                {canUpdate && (
                  <>
                    <input
                      type="file"
                      id="fuBannerImage"
                      onChange={handleNewsBannerImage}
                      style={{ display: "none" }}
                    />
                    <label
                      className="btn btn-primary"
                      for="fuBannerImage"
                      style={{ color: "white" }}
                    >
                      <i className="fa fa-upload" />{" "}
                      {newsBannerImage ? "Change Image" : "Upload Image"}{" "}
                    </label>
                    <br />
                    <br />
                  </>
                )}
                <img src={newsBannerImage} style={{ height: "200px" }}></img>
              </div>

              <div className="form-group rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <LoadingSpinner /> : renderPage;
};

const enhancer = withFormik({
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default compose(enhancer, connect(mapStateToProps))(NewsBanner);
