import React, { useState, useEffect } from "react";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import { compose } from "redux";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const CategoryBanner = props => {
  const menuName = "categories";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const CreatedByID = props.authData.userID;

  const history = useHistory();

  const [categoryBannerImage, setCategoryBannerImage] = useState("");
  const [initialCategoryBannerImage, setInitialCategoryBannerImage] = useState(
    ""
  );
  const [categoryBannerInput, setCategoryBannerInput] = useState(null);

  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const key = AWSS3BucketKey.categoriesBanner;

  useEffect(() => {
    if (!canUpdate) {
      history.push("/category");
    }
    GetStaticPageData();
  }, []);

  const GetStaticPageData = async () => {
    const endpoint = "CategoryMaster/getStaticPageCategoryData";
    setIsLoading(true);
    const { data } = await API(APIMethod.GET, endpoint, null);
    if (data.length > 0) {
      setCategoryBannerImage(data[0].bannerImage);
      setInitialCategoryBannerImage(data[0].bannerImage);
    }

    setIsLoading(false);
  };

  function handleCategoryBannerImage(e) {
    if (e.target.files.length > 0) {
      setCategoryBannerInput(e.target.files[0]);
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setCategoryBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setCategoryBannerInput(null);
      setCategoryBannerImage(initialCategoryBannerImage);
    }
  }

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit, submitCount, errors } = props;
    if (isValid && categoryBannerInput) {
      setIsLoading(true);

      const ext = categoryBannerInput.name.substring(
        categoryBannerInput.name.lastIndexOf(".") + 1
      );
      const uploadKey = key + (generate_uuidv4() + "." + ext);
      let bucket = AWS_SDK.bucket;

      //Remove existing file from AWS
      if (
        initialCategoryBannerImage &&
        initialCategoryBannerImage.includes(
          `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
        )
      ) {
        await s3DeleteFromURL(initialCategoryBannerImage);
      }
      const categoryBannerLocation = await UploadFile(
        categoryBannerInput,
        bucket,
        uploadKey
      );

      const param = {
        bannerImage: categoryBannerLocation,
        isActive,
        CreatedByID
      };
      const endpoint = "CategoryMaster/manageStaticPageCategory";
      const { data } = await API(APIMethod.PUT, endpoint, param);
      setIsLoading(false);
      GetStaticPageData();
    }
    handleSubmit();
  };

  const renderPage = (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Category Banner
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                <label>Category List Banner Image</label>
                <br />
                {canUpdate && (
                  <>
                    <input
                      type="file"
                      id="fuBannerImage"
                      onChange={handleCategoryBannerImage}
                      accept="image/gif, image/jpeg, image/png"
                      style={{ display: "none" }}
                    />
                    <label
                      className="btn btn-primary"
                      for="fuBannerImage"
                      style={{ color: "white" }}
                    >
                      <i className="fa fa-upload" />{" "}
                      {categoryBannerImage ? "Change Image" : "Upload Image"}{" "}
                    </label>
                    <br />
                    <br />
                  </>
                )}
                <img
                  src={categoryBannerImage}
                  style={{ height: "200px" }}
                ></img>
              </div>

              <div className="form-group rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <LoadingSpinner /> : renderPage;
};

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

const enhancer = withFormik({
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default compose(enhancer, connect(mapStateToProps))(CategoryBanner);
