import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import Select from "react-select";
import Button from "components/button/Button";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import API, { APIMethod } from "../../../../util/ApiHandler";

const UserRights = props => {
  const menuName = "userManagement";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const LoggedinUser = props.authData.userID;
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const CreatedByID = props.authData.userID;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userID, SelectedOptions] = useState(
    localStorage.getItem("UserID") ? localStorage.getItem("UserID") : ""
  );
  const [isUserIDDisable, setcategoryidDisable] = useState(
    localStorage.getItem("UserID") ? true : false
  );
  const [BasicSelectOption, setBasicSelectOption] = useState([]);
  const [Uservalue, setUservalue] = useState(undefined);
  const [dummyData, setDummyData] = useState([]);
  const [UpdatedData, setUpdatedData] = useState([]);
  const uservalues = [];
  const userData = [];

  useEffect(() => {
    if (!canUpdate) {
      history.push("/dashboard");
    }
    if (BasicSelectOption.length == 0) {
      getUserData();
    }
  }, []);

  const getUserData = async () => {
    const endpoint =
      "UserMaster/getUserDataExceptLoginUser?userID=" + LoggedinUser;
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.forEach((item, i) => {
      if (userID != "") {
        if (item.userID === userID) {
          uservalues.push({
            value: item.userID,
            label: item.firstName + " " + item.lastName + " - " + item.userType
          });
        }
      }
      userData.push({
        value: item.userID,
        label: item.firstName + " " + item.lastName + " - " + item.userType
      });
    });
    setUservalue(uservalues);
    setBasicSelectOption(userData);
  };

  useEffect(() => {
    if (userID) {
      getUserPermission(userID);
      localStorage.clear();
    }
  }, [userID]);

  const handleuserChange = selectedOption => {
    setUservalue(selectedOption);
    SelectedOptions(selectedOption.value);
  };
  const getUserPermission = async selectedOption => {
    setIsLoading(true);
    setDummyData([]);
    setUpdatedData([]);
    const endpoint =
      "UserMaster/getUserWisePermission?userID=" + selectedOption;
    const { data } = await API(APIMethod.GET, endpoint, null);

    setDummyData(data);
    setUpdatedData(data);
    setIsLoading(false);
  };

  const styles = {
    // this one works
    indicatorsContainer: provided => ({
      ...provided,
      background: "lightgray"
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "black" // Custom colour
    })
  };

  const formAction = async (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "Save") {
      const endpoint = "UserMaster/AddUserRights?userId=" + CreatedByID;
      const params = UpdatedData;
      const { data } = await API(APIMethod.POST, endpoint, params);
      toast.success("Permission Updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      localStorage.clear();
    }
  };
  const eventDeleteHandler = (moduleID, type) => {
    const filterdata = UpdatedData;
    const data = filterdata.filter(x => x.moduleID === moduleID)[0];
    switch (type) {
      case "CanView":
        filterdata.filter(x => x.moduleID === moduleID)[0].canView =
          data.canView === true ? false : true;
        break;
      case "CanAdd":
        filterdata.filter(x => x.moduleID === moduleID)[0].canAdd =
          data.canAdd === true ? false : true;
        break;
      case "CanUpdate":
        filterdata.filter(x => x.moduleID === moduleID)[0].canUpdate =
          data.canUpdate === true ? false : true;
        break;
      case "CanDelete":
        filterdata.filter(x => x.moduleID === moduleID)[0].canDelete =
          data.canDelete === true ? false : true;
        break;
    }
    setUpdatedData(filterdata);
  };

  return (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>User Permissions
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                {/* <div className="roe-card-description">Category</div> */}
                <label>User</label>
                <Select
                  placeholder="Select User"
                  value={Uservalue}
                  onChange={handleuserChange}
                  options={BasicSelectOption}
                  required={true}
                  styles={styles}
                />
              </div>

              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="roe-card-style mtb-15">
                  <div
                    className="roe-card-header module-header"
                    style={{ display: "flex" }}
                  >
                    <div className="flex-1">
                      <span className="hash"># </span> Permissions
                    </div>

                    <div>
                      {canUpdate && (
                        <Button
                          className="c-btn ma-5 c-primary"
                          onClick={() => formAction("Save")}
                        >
                          <i className="fa-sharp fa-solid fa-floppy-disk mr-10"></i>
                          {/* <i className="fas fa-file mr-10" /> */}
                          Save
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table
                      className="table table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Module Name</th>
                          <th>View</th>
                          <th>Add</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dummyData &&
                          dummyData.length > 0 &&
                          dummyData.map((e, i) => {
                            return (
                              <tr key={i}>
                                <td>{e.moduleName}</td>
                                <td>
                                  <input
                                    id={"chkView" + e.moduleID}
                                    type="checkbox"
                                    value={e.moduleID}
                                    defaultChecked={e.canView}
                                    onChange={() =>
                                      eventDeleteHandler(e.moduleID, "CanView")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    id={"chkAdd" + e.moduleID}
                                    type="checkbox"
                                    value={e.moduleID}
                                    onChange={() =>
                                      eventDeleteHandler(e.moduleID, "CanAdd")
                                    }
                                    defaultChecked={e.canAdd}
                                  />
                                </td>
                                <td>
                                  <input
                                    id={"chkUpdate" + e.moduleID}
                                    type="checkbox"
                                    value={e.moduleID}
                                    onChange={() =>
                                      eventDeleteHandler(
                                        e.moduleID,
                                        "CanUpdate"
                                      )
                                    }
                                    defaultChecked={e.canUpdate}
                                  />
                                </td>
                                <td>
                                  <input
                                    id={"chkdelete" + e.moduleID}
                                    type="checkbox"
                                    value={e.moduleID}
                                    onChange={() =>
                                      eventDeleteHandler(
                                        e.moduleID,
                                        "CanDelete"
                                      )
                                    } //checked={e.canUpdate}
                                    defaultChecked={e.canDelete}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        {dummyData.length == 0 && (
                          <tr>
                            <td colSpan={5}>
                              <center> No data Available </center>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(UserRights);
