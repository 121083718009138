import React, { useState, useEffect } from "react";
import { ProfileLockScreen } from "./../../helper/constant";
import API, { APIMethod } from "../../util/ApiHandler";



const ProfileInfoComponent = ({ role, userImage, LoggedinUserId }) => {

    const [image, setImage] = useState();
    const [userrole, setRole] = useState();
    const [userId, setUserID] = useState();

    const handleImage = e => {
        if (e.target.files.length > 0) {
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            reader.onload = () => {
                setImage(reader.result); //base64encoded string
                const endpoint = "UserMaster/updateProfileImage?id=" + userId;
                const params = {
                    image: reader.result,
                    updatedByID: LoggedinUserId
                }
                const { data } =  API(APIMethod.PUT, endpoint, params);
            };
            reader.onerror = error => {
                console.log("Error: ", error);
            };

        }

    }
    const changeimage = async () => {
        const endpoint = "UserMaster/updateProfileImage?id=" + userId;
        const params = {
            image: image,
            updatedByID: LoggedinUserId
        }
        const { data } = await API(APIMethod.PUT, endpoint, params);
    }
    useEffect(() => {
        setRole(role);
        setImage(userImage);
        setUserID(LoggedinUserId);

    }, [role, userImage, LoggedinUserId]);
    return (
        <div>
            <div className="text-center profile-image">
                <input
                    type="file"
                    id="fuImage"
                    onChange={handleImage}
                    style={{ display: "none" }}
                />
                <label for="fuImage"> <img src={image ? image : ProfileLockScreen} alt="Name" for="fuImage" /></label>



            </div>
            <div className="pa-10" >
                <div style={{ textAlign: "center", color: '#563c91' }} className="headline" >
                    {userrole}
                </div>

            </div>
        </div>
    );
};

export default ProfileInfoComponent;
