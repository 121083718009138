import React, { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import PageBanner from "./PageBanner";
import TopHeader from "./TopHeader";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "assets/scss/preview-page.scss";

const Preview = props => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [image, setImage] = useState();
  const [bannerImage, setBannerImage] = useState();

  useEffect(() => {
    setTitle(localStorage.getItem("PreviewTitle"));
    setDescription(localStorage.getItem("PreviewDescription"));
    setShortDescription(localStorage.getItem("PreviewShortDescription"));
    setImage(localStorage.getItem("PreviewImage"));
    setBannerImage(localStorage.getItem("PreviewBanner"));

    localStorage.removeItem("PreviewTitle");
    localStorage.removeItem("PreviewDescription");
    localStorage.removeItem("PreviewShortDescription");
    localStorage.removeItem("PreviewImage");
    localStorage.removeItem("PreviewBanner");
  }, []);

  return (
    <>
      <TopHeader />
      <Navbar />

      <div style={{ height: "100vh", overflow: "auto" }}>
        <PageBanner
          pageTitle={
            title ? title : "" // "Service"
          }
          bgImage="page-title-one"
          bgURL={
            bannerImage && bannerImage != "null" && bannerImage != ""
              ? bannerImage
              : null
          }
        />

        <div className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-item">
                  <div className="about-left">
                    <img
                      src={
                        image && image != "null" && image != ""
                          ? image
                          : "/images/default-about-us.jpg"
                      }
                      alt="About"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-item about-right">
                  <img src="/images/about-shape1.png" alt="About" />
                  {!shortDescription && (
                    <div className="text-center">
                      <h2>Information not available at the moment.</h2>
                    </div>
                  )}
                  {shortDescription && (
                    <div className="dynamic-Content ql-editor">
                      {HTMLReactParser(shortDescription)}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="about-item">
                  {!description && (
                    <div className="text-center">
                      <h2>Information not available at the moment.</h2>
                    </div>
                  )}
                  {description && (
                    <div className="dynamic-Content">
                      {HTMLReactParser(description)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Preview;
