import React, { useState } from "react";
import { loginBack, iconDemo, icon, shortIcon } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import axios from "axios";
import { variables } from "Variables.js";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import CryptoJS from "crypto-js";

const { login } = AuthActions;

const Login = props => {
  const [isLoading, setIsLoading] = useState(false);
  const secretPass = "XkhZG4fW2t2W";

  const encryptData = text => {
    const key = "55a51621a6648525";
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    const enc = CryptoJS.AES.encrypt(text, keyutf, { iv: iv });
    return enc.toString();
  };

  const handleLogin = e => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    if (values.emailAddress !== "" && values.password !== "") {
      setIsLoading(true);
      axios
        .post(variables.API_URL + "Authentication/Login", {
          EmailAddress: values.emailAddress,
          password: encryptData(values.password)
        })
        .then(postData => {
          console.log(postData.data);
          const data = {
            token: postData.data.token,
            userID: postData.data.user.userID,
            permission: postData.data.permissions
          };
          // using this method you can store token in redux
          props.login(data);
          props.history.push("/dashboard");
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          toast.error("Email or password is invalid.", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }

    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={shortIcon} alt="icon" height="70px" />
        </div>
        <div className="login-title">Sign in to your cccount</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="emailAddress"
              onChange={handleChange}
              value={values.emailAddress}
              onBlur={handleBlur}
              placeholder="Email Address"
            />
            <Error field="emailAddress" />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control react-form-input"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            />
            <Error field="password" />
          </div>

          <div className="form-check text-center mtb-16">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Remember me
            </label>
          </div>
          {!isLoading ? (
            <button type="submit" className="btn form-button">
              Login
            </button>
          ) : (
            <label className="btn form-button">
              <div className="spinner-container" style={{ height: "27px" }}>
                <div
                  className="loading-spinner"
                  style={{ width: "20px", height: "20px" }}
                ></div>
              </div>
            </label>
          )}
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(Login);
