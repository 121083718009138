import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";
import { values } from "lodash";

const UpdateFacility = props => {
  const menuName = "facilities";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const UpdatedByID = props.authData.userID;

  const history = useHistory();
  const [facilityName, setFacilityName] = useState("");
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [facilityImage, setFacilityImage] = useState("");
  const [initialFacilityImage, setInitialFacilityImage] = useState("");
  const [facilityInput, setFacilityInput] = useState(null);
  const [bannerImage, setBannerImage] = useState("");
  const [initialBannerImage, setInitialBannerImage] = useState("");
  const [bannerInput, setBannerInput] = useState(null);
  const [address, setAddress] = useState("");
  const [addressUrl, setAddressUrl] = useState("");
  const [id, setID] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values
  } = props;

  const key1 = AWSS3BucketKey.facilities;
  const key2 = AWSS3BucketKey.facilitiesBanner;

  useEffect(() => {
    if (!canUpdate) {
      history.push("/facility");
    }
  }, []);

  function handleFacilityImage(e) {
    if (e.target.files.length > 0) {
      setFacilityInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setFacilityImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setFacilityInput(null);
      setFacilityImage(initialFacilityImage);
    }
  }

  function handleBannerImage(e) {
    if (e.target.files.length > 0) {
      setBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBannerInput(null);
      setBannerImage(initialBannerImage);
    }
  }

  useEffect(() => {
    setID(localStorage.getItem("ID"));

    setFacilityName(localStorage.getItem("FacilityName"));
    setDescription(localStorage.getItem("Description"));
    setMetaTitle(localStorage.getItem("seoMetaTitle"));
    setMetaDescription(localStorage.getItem("seoMetaDescription") || "");
    setFacilityImage(localStorage.getItem("FacilityImage"));
    setInitialFacilityImage(localStorage.getItem("FacilityImage"));
    setBannerImage(localStorage.getItem("BannerImage"));
    setInitialBannerImage(localStorage.getItem("BannerImage"));
    setIsActive(localStorage.getItem("IsActive") == "false" ? false : true);
    setAddress(localStorage.getItem("facilityAddress"));
    setAddressUrl(localStorage.getItem("facilityAddressMapURL"));
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"]
    ]
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const showPreview = () => {
    let { values } = props;
    localStorage.setItem("PreviewTitle", values.txtFacilityName);
    localStorage.setItem("PreviewBanner", bannerImage);
    localStorage.setItem("PreviewDescription", description);
    window.open("/preview", "_blank", "noopener,noreferrer");
  };

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit, submitCount, errors } = props;
    var facilityName = values.txtFacilityName;
    if (isValid && facilityName) {
      let facilityLocation = facilityImage != "" ? facilityImage : null;
      let bannerLocation = bannerImage != "" ? bannerImage : null;

      if (facilityInput) {
        const ext = facilityInput.name.substring(
          facilityInput.name.lastIndexOf(".") + 1
        );
        const uploadKey = key1 + (generate_uuidv4() + "." + ext);
        let bucket = AWS_SDK.bucket;

        //Remove existing file from AWS
        if (
          initialFacilityImage &&
          initialFacilityImage.includes(
            `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
          )
        ) {
          await s3DeleteFromURL(initialFacilityImage);
        }
        facilityLocation = await UploadFile(facilityInput, bucket, uploadKey);
      }

      if (bannerInput) {
        const ext = bannerInput.name.substring(
          bannerInput.name.lastIndexOf(".") + 1
        );
        const uploadKey = key2 + (generate_uuidv4() + "." + ext);
        let bucket = AWS_SDK.bucket;

        //Remove existing file from AWS
        if (
          initialBannerImage &&
          initialBannerImage.includes(
            `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
          )
        ) {
          await s3DeleteFromURL(initialBannerImage);
        }
        bannerLocation = await UploadFile(bannerInput, bucket, uploadKey);
      }

      const endpoint = "Facility/" + id;

      const params = {
        facilityName,
        description,
        facilityImage: facilityLocation,
        bannerImage: bannerLocation,
        isActive,
        UpdatedByID,
        SeoMetaTitle: metaTitle,
        SeoMetaDescription: metaDescription,
        FacilityAddress: address,
        FacilityAddressMapURL: addressUrl
      };

      const { data } = await API(APIMethod.PUT, endpoint, params);
      toast.success("Facility Updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      history.push("/facility");
    }
    handleSubmit();
  };

  const cancel = () => {
    history.push("/facility");
  };

  return (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Update Facility
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                <label>Facility Name</label>
                <input
                  id="txtFacilityName"
                  type="text"
                  value={values.txtFacilityName}
                  className="form-control react-form-input"
                  onChange={handleChange}
                  //onChange={(e) => setFacilityName(e.target.value)}
                  placeholder="Enter Facility"
                  onBlur={handleBlur}
                  maxLength={50}
                />
                <Error field="txtFacilityName" />
              </div>

              <div className="form-group">
                <label>Description</label>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={setDescription}
                  value={description}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  id="FacilityAddress"
                  value={address}
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setAddress(e?.target?.value)}
                  placeholder="Enter Address"
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label>Address Map URL</label>
                <input
                  id="FacilityAddressMapURL"
                  value={addressUrl}
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setAddressUrl(e?.target?.value)}
                  placeholder="Enter Address Map URL"
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label>Facility Image</label>
                <br />
                <input
                  type="file"
                  id="fuFacilityImage"
                  onChange={handleFacilityImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuFacilityImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {facilityImage &&
                  facilityImage != "" &&
                  facilityImage != "null"
                    ? "Change Image"
                    : "Upload Image"}{" "}
                </label>
                {facilityImage &&
                  facilityImage != "" &&
                  facilityImage != "null" && (
                    <>
                      <br />
                      <br />
                      <img
                        src={facilityImage}
                        style={{ height: "200px" }}
                      ></img>
                    </>
                  )}
              </div>

              <div className="form-group">
                <label>Facility Banner Image</label>
                <br />
                <input
                  type="file"
                  id="fuBannerImage"
                  onChange={handleBannerImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuBannerImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {bannerImage && bannerImage != "" && bannerImage != "null"
                    ? "Change Image"
                    : "Upload Image"}{" "}
                </label>
                {bannerImage && bannerImage != "" && bannerImage != "null" && (
                  <>
                    <br />
                    <br />
                    <img src={bannerImage} style={{ height: "200px" }}></img>
                  </>
                )}
              </div>

              <div className="form-group">
                <div className="pretty p-svg p-curve">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(prev => !prev)}
                  />
                  <div className="state p-success">
                    {/* <!-- svg path --> */}
                    <svg className="svg svg-icon" viewBox="0 0 20 20">
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: "white", fill: "white" }}
                      ></path>
                    </svg>
                    <label>Active</label>
                  </div>
                </div>
              </div>

              <hr />
              <div>
                <div className="roe-card-header pt-0 pl-0 pr-0">SEO</div>
                <div className="form-group">
                  <label>Meta Title</label>
                  <input
                    id="SeoMetaTitle"
                    value={metaTitle}
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMetaTitle(e?.target?.value)}
                    placeholder="Enter Meta Title"
                    onBlur={handleBlur}
                  />
                  {/* <Error field="seoMetaTitle" /> */}
                </div>
                <div className="form-group">
                  <label>Meta Description</label>
                  <input
                    id="SeoMetaDescription"
                    value={metaDescription}
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMetaDescription(e?.target?.value)}
                    placeholder="Enter Meta Description"
                    onBlur={handleBlur}
                  />
                  {/* <Error field="seoMetaTitle" /> */}
                </div>
              </div>
              <div className="rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={showPreview}
                >
                  Preview
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    txtFacilityName: Yup.string().required("This Field is required")
  }),
  mapPropsToValues: props => ({
    txtFacilityName: localStorage.getItem("FacilityName")
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default compose(enhancer, connect(mapStateToProps))(UpdateFacility);
