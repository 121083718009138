import React, { useState, useEffect } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import Select from "react-select";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const UpdateSubSubCategory = props => {
  const menuName = "categories";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const UpdatedByID = props.authData.userID;

  const [BasicSelectOption, setBasicSelectOption] = useState([]);
  const [subcategoryvalue, setsubcategoryvalue] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const subcategoryvalues = [];
  const subCategoryData = [];

  const key = AWSS3BucketKey.categoriesBanner;

  useEffect(() => {
    if (!canUpdate) {
      history.push("/subsubcategory");
    }
    if (BasicSelectOption.length == 0) {
      binddata();
    }
  });
  const binddata = async () => {
    try {
      const endpoint = "SubCategoryMaster/getSubCategoryData";
      const { data } = await API(APIMethod.GET, endpoint, null);

      data.forEach((item, i) => {
        if (item.subCategoryID === localStorage.getItem("SubCategoryID")) {
          subcategoryvalues.push({
            value: item.subCategoryID,
            label: item.subCategoryName
          });
        }
        subCategoryData.push({
          value: item.subCategoryID,
          label: item.subCategoryName
        });
      });
      setsubcategoryvalue(subcategoryvalues);
      setBasicSelectOption(subCategoryData);
    } catch (error) {
      console.log(error);
      setsubcategoryvalue([]);
      setBasicSelectOption([]);
    }
  };

  const history = useHistory();

  const [subcategoryID, setSubCategoryID] = useState(
    localStorage.getItem("SubCategoryID")
  );

  const [subsubCategoryName, setSubSubCategoryName] = useState(
    localStorage.getItem("SubSubCategoryName")
  );
  const [shortDescription, setShortDescription] = useState(
    localStorage.getItem("ShortDescription")
  );
  const [id, setID] = useState(localStorage.getItem("ID"));
  const [description, setValue] = useState(localStorage.getItem("Description"));
  const [subsubCategoryLogo, setSubSubCategoryLogo] = useState(
    localStorage.getItem("SubSubCategoryLogo")
  );
  const [bannerImage, setBannerImage] = useState(
    localStorage.getItem("BannerImage")
  );
  const [metaTitle, setMetaTitle] = useState(
    localStorage.getItem("seoMetaTitle")
  );
  const [metaDescription, setMetaDescription] = useState(
    localStorage.getItem("seoMetaDescription")
  );
  const [initialBannerImage, setInitialBannerImage] = useState(
    localStorage.getItem("BannerImage")
  );
  const [bannerInput, setBannerInput] = useState(null);

  const [isActive, setIsActive] = useState(
    localStorage.getItem("IsActive") == "false" ? false : true
  );

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values
  } = props;

  const handleSubCategoryChange = selectedOption => {
    setsubcategoryvalue(selectedOption);
    setSubCategoryID(selectedOption.value);
  };

  function handleBannerImage(e) {
    if (e.target.files.length > 0) {
      setBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBannerInput(null);
      setBannerImage(initialBannerImage);
    }
  }

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"]
    ]
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };
  const showPreview = () => {
    let { values } = props;
    localStorage.setItem("PreviewTitle", values.txtSubSubCategoryName);
    localStorage.setItem("PreviewBanner", bannerImage);
    localStorage.setItem("PreviewDescription", description);
    window.open("/preview", "_blank", "noopener,noreferrer");
  };
  const handleSubmit = async () => {
    let { values, isValid, handleSubmit } = props;
    var subSubCategoryName = values.txtSubSubCategoryName;
    if (subcategoryID) {
      if (isValid && subSubCategoryName) {
        setIsLoading(true);
        let bannerLocation = bannerImage != "" ? bannerImage : null;

        if (bannerInput) {
          const ext = bannerInput.name.substring(
            bannerInput.name.lastIndexOf(".") + 1
          );
          const uploadKey = key + (generate_uuidv4() + "." + ext);
          let bucket = AWS_SDK.bucket;

          //Remove existing file from AWS
          if (
            initialBannerImage &&
            initialBannerImage.includes(
              `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
            )
          ) {
            await s3DeleteFromURL(initialBannerImage);
          }
          bannerLocation = await UploadFile(bannerInput, bucket, uploadKey);
        }

        const endpoint = "SubSubCategoryMaster/" + id;
        const params = {
          subcategoryID,
          subSubCategoryName,
          shortDescription,
          description,
          subsubCategoryLogo,
          bannerLocation,
          UpdatedByID,
          SeoMetaTitle: metaTitle,
          SeoMetaDescription: metaDescription
        };
        const { data } = await API(APIMethod.PUT, endpoint, params);

        setIsLoading(false);
        toast.success("Sub-Sub-Category Updated Successfully.", {
          position: toast.POSITION.TOP_RIGHT
        });
        history.push("/subsubcategory");
      }
    } else {
      toast.error("Please Select Sub-Category.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    handleSubmit();
  };

  const cancel = () => {
    history.push("/subsubcategory");
  };
  const styles = {
    // this one works
    indicatorsContainer: provided => ({
      ...provided,
      background: "lightgray"
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "black" // Custom colour
    })
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Update Sub-Sub-Category
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                {/* <div className="roe-card-description">Sub-Category</div> */}
                <label>Sub-Category</label>
                <Select
                  value={subcategoryvalue}
                  placeholder="Select Sub-Category"
                  options={BasicSelectOption}
                  onChange={handleSubCategoryChange}
                  styles={styles}
                />
              </div>
              <div className="form-group">
                <label>Sub-Sub-Category Name</label>

                <input
                  id="txtSubSubCategoryName"
                  type="text"
                  value={values.txtSubSubCategoryName}
                  className="form-control react-form-input"
                  onChange={handleChange}
                  //onChange={(e) => setCategoryName(e.target.value)}
                  placeholder="Enter Sub-Sub-Category"
                  onBlur={handleBlur}
                  maxLength={50}
                />
                <Error field="txtSubSubCategoryName" />
              </div>
              <div className="form-group">
                <label>Short Description</label>
                <input
                  type="text"
                  value={shortDescription}
                  className="form-control react-form-input"
                  onChange={e => setShortDescription(e.target.value)}
                  placeholder="Enter Short Description"
                  maxLength={100}
                />
              </div>

              <div className="form-group">
                <label>Description</label>

                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={setValue}
                  value={description}
                />
              </div>

              <div className="form-group">
                <label>Sub-Sub-Category Icon</label>
                <input
                  type="text"
                  value={subsubCategoryLogo}
                  className="form-control react-form-input"
                  onChange={e => setSubSubCategoryLogo(e.target.value)}
                  placeholder="Enter Sub-Sub-Category Icon"
                />
                <span style={{ color: "gray" }}>e.g: fa fa-pencil</span>
              </div>

              <div className="form-group">
                <label>Sub-Sub-Category Banner Image</label>
                <br />
                <input
                  type="file"
                  id="fuBannerImage"
                  onChange={handleBannerImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuBannerImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {bannerImage && bannerImage != "" && bannerImage != "null"
                    ? "Change Image"
                    : "Upload Image"}{" "}
                </label>
                {bannerImage && bannerImage != "" && bannerImage != "null" && (
                  <>
                    <br />
                    <br />
                    <img src={bannerImage} style={{ height: "200px" }}></img>
                  </>
                )}
              </div>

              <div className="form-group">
                <div className="pretty p-svg p-curve">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(prev => !prev)}
                  />
                  <div className="state p-success">
                    {/* <!-- svg path --> */}
                    <svg className="svg svg-icon" viewBox="0 0 20 20">
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: "white", fill: "white" }}
                      ></path>
                    </svg>
                    <label>Active</label>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="roe-card-header pt-0 pl-0 pr-0">SEO</div>
                <div className="form-group">
                  <label>Meta Title</label>
                  <input
                    id="SeoMetaTitle"
                    value={metaTitle}
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMetaTitle(e?.target?.value)}
                    placeholder="Enter Meta Title"
                    onBlur={handleBlur}
                  />
                  {/* <Error field="seoMetaTitle" /> */}
                </div>
                <div className="form-group">
                  <label>Meta Description</label>
                  <input
                    id="SeoMetaDescription"
                    value={metaDescription}
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMetaDescription(e?.target?.value)}
                    placeholder="Enter Meta Description"
                    onBlur={handleBlur}
                  />
                  {/* <Error field="seoMetaTitle" /> */}
                </div>
              </div>
              <div className="rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={showPreview}
                >
                  Preview
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    txtSubSubCategoryName: Yup.string().required("This Field is required")
  }),
  mapPropsToValues: props => ({
    txtSubSubCategoryName: localStorage.getItem("SubSubCategoryName")
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps)
)(UpdateSubSubCategory);
