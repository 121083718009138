import React from "react";
import { NavLink } from "react-router-dom";

const CustomPopoverBlock = props => {
  const messageLength = 40;
  const { type, data } = props;

  const closePopover = () => {
    document.body.click();
  };

  return (
    <>
      {type == "inquiry" && (
        <NavLink
          to={`/inquiry?data=${data.inquiryID}`}
          onClick={() => {
            closePopover();
          }}
        >
          <div className="mail-popover-block">
            <div className="flex-x">
              <div className="flex-1">
                <div className="fs-13 demi-bold-text">{data.name}</div>
                <div className="fs-11 medium-text">
                  {data.description.length > messageLength
                    ? data.description.substr(0, messageLength) + "..."
                    : data.description}
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}
      {type == "inquiryAll" && (
        <NavLink
          to={`/inquiry`}
          onClick={() => {
            closePopover();
          }}
        >
          <div className="mail-popover-block">
            <div className="flex-x">
              <div className="flex-1">
                <div className="fs-13 demi-bold-text text-center">See ALL</div>
              </div>
            </div>
          </div>
        </NavLink>
      )}
      {type == "ratingReview" && (
        <NavLink
          to={`/rating-review?data=${data.ratingReviewID}`}
          onClick={() => {
            closePopover();
          }}
        >
          <div className="mail-popover-block">
            <div className="flex-x">
              <div className="flex-1">
                <div className="fs-13 demi-bold-text">{data.name}</div>
                <div className="fs-11 medium-text">
                  {data.review.length > messageLength
                    ? data.review.substr(0, messageLength) + "..."
                    : data.review}
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}
      {type == "ratingReviewAll" && (
        <NavLink
          to={`/rating-review`}
          onClick={() => {
            closePopover();
          }}
        >
          <div className="mail-popover-block">
            <div className="flex-x">
              <div className="flex-1">
                <div className="fs-13 demi-bold-text text-center">See ALL</div>
              </div>
            </div>
          </div>
        </NavLink>
      )}
    </>
  );
};

export default CustomPopoverBlock;
