import React, { useState, useMemo, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../../reacttbl.style";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { connect } from "react-redux";
import API, { APIMethod } from "../../../../util/ApiHandler";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const StaticPages = props => {
  const menuName = "staticpages";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;

  const history = useHistory();
  const [dummyData, setDummyData] = useState([]);
  const [warningAlert, setWarningAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    getStaticPageData();
  }, []);
  const getStaticPageData = async () => {
    setIsLoading(true);
    const endpoint = "StaticPageManagement/getStaticPageData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.forEach((el, index) => {
      const regex = /(<([^>]+)>)/gi;
      data[index].description = el.description.replace(regex, "");
      data[index].description =
        data[index].description.length > 50
          ? data[index].description.substr(0, 50) + "..."
          : data[index].description;
    });
    setDummyData(data);
    setIsLoading(false);
  };

  const setData = data => {
    let {
      staticPageID,
      title,
      shortDescription,
      description,
      bannerImage,
      image
    } = data;
    localStorage.setItem("ID", staticPageID);
    localStorage.setItem("Title", title);
    localStorage.setItem("ShortDescription", shortDescription);
    localStorage.setItem("Description", description);
    localStorage.setItem("BannerImage", bannerImage ?? "");
    localStorage.setItem("Image", image ?? "");
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Page Name"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "title"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Description"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "description"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              {canUpdate && (
                <button
                  className="react-table-edit-button"
                  onClick={() => editClick(tableInstance.row.original)}
                >
                  <i className="fas fa-edit" />
                </button>
              )}
            </div>
          );
        }
      }
    ],
    []
  );

  const editClick = async updatedata => {
    setIsLoading(true);
    const endpoint = "StaticPageManagement/" + updatedata.staticPageID;
    const { data } = await API(APIMethod.GET, endpoint, null);
    setData(data);
    setIsLoading(false);
    history.push("/updatestaticpage");
  };

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: dummyData,
      columns: columns,
      initialState: {
        hiddenColumns: [!canUpdate ? "id" : ""],
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ReactTableWrapper {...props}>
      <div className="roe-card-style mt-15 mb-30">
        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Static Pages
          </div>

          <div className="mr-10"></div>
        </div>
        <div className="table-container  overflow-auto">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => {
                    return (
                      <td>
                        <div>
                          {header.canFilter ? header.render("Filter") : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          onPageChange={gotoPage}
          pages={pageCount}
          page={pageIndex}
        />
      </div>
    </ReactTableWrapper>
  );
};
const FilterComponent = tableInstance => {
  const { filterValue, setFilter } = tableInstance.column;
  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="tabl-search react-form-input"
      placeholder={`Search ${tableInstance.column.placeholder}`}
      onClick={e => e.stopPropagation()}
    />
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(StaticPages);
