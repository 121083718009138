import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import Select from "react-select";
import ReviewListPending from "./ReviewListPending";
import ReviewListApproved from "./ReviewListApproved";
import ReviewListRejected from "./ReviewListRejected";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../../util/ApiHandler";

const RatingReview = props => {
  const menuName = "ratingReview";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const LoggedinUser = props.authData.userID;

  const history = useHistory();
  const [showFacilityRating, setShowFacilityRating] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    GetWebsiteSettingsData();
  }, []);

  const GetWebsiteSettingsData = async () => {
    setIsLoading(true);
    const endpoint = "WebsiteSettings/getWebsiteSettingsData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    if (data.length > 0) {
      setShowFacilityRating(data[0].showFacilityRating ?? showFacilityRating);
    }
    setIsLoading(false);
  };

  const changehandleShowFacilityRating = e => {
    const currentVal = e.target.checked;
    setShowFacilityRating(currentVal);
    handleShowFacilityRating(currentVal);
  };

  const handleShowFacilityRating = async showFacilityRatingVal => {
    // setIsLoading(true);
    try {
      const endpoint = "WebsiteSettings/manageWebsiteSettings";
      const params = {
        showFacilityRating: showFacilityRatingVal,
        isActive
      };
      const { data } = await API(APIMethod.POST, endpoint, params);
      toast.success("Settings Updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch {
      // setIsLoading(false);
    }
  };

  const [status, setStatus] = useState({ value: "pending", label: "Pending" });

  const statusOptions = [
    {
      value: "pending",
      label: "Pending"
    },
    {
      value: "approved",
      label: "Approved"
    },
    {
      value: "rejected",
      label: "Hidden"
    }
  ];

  const handleStatus = e => {
    setStatus(e);
  };

  const renderPage = (
    <>
      <div className="roe-card-style mt-15 mb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Ratings & Reviews
          </div>
        </div>
        <div className="roe-card-body pb-2">
          <form>
            <div className="form-group mb-0">
              <div className="d-flex justify-content-between">
                {canUpdate && (
                  <div className="pretty p-switch p-fill">
                    <input
                      type="checkbox"
                      checked={showFacilityRating}
                      onChange={changehandleShowFacilityRating}
                    />
                    <div className="state p-success">
                      <label>On / Off</label>
                    </div>
                  </div>
                )}
                <div className="col-sm-4">
                  <div className="form-group">
                    <Select
                      placeholder="Select Status"
                      options={statusOptions}
                      onChange={handleStatus}
                      value={status}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="roe-card-style mt-15 mb-30">
        <div className="roe-card-body mt-2">
          <div>
            <div>
              {status.value == "approved" ? (
                <ReviewListApproved />
              ) : status.value == "rejected" ? (
                <ReviewListRejected />
              ) : (
                <ReviewListPending />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return isLoading ? <LoadingSpinner /> : renderPage;
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(RatingReview);
