import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../../reacttbl.style";
import Button from "components/button/Button";
import { variables } from "Variables.js";
import RoyTooltip from "components/common/RoyTooltip";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import useQuery from "helper/useQuery";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../../util/ApiHandler";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const Inquiry = props => {
  const menuName = "contactus";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const LoggedinUserID = props.authData.userID;

  const history = useHistory();

  let query = useQuery();
  const recordID = query.get("data");

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [warningAlert, setWarningAlert] = useState(false);
  const [deleteId, setDeleteID] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    getListData();
  }, []);

  useEffect(() => {
    if (recordID) {
      GetRecordByID(recordID);

      // // Remove query params
      // const queryParams = new URLSearchParams(location.search);
      // if (queryParams.has("data")) {
      //   queryParams.delete("data");
      //   history.replace({
      //     search: queryParams.toString()
      //   });
      // }
      // // END Remove query params
    }
  }, [recordID]);

  const GetRecordByID = async recordID => {
    const endpoint = `Inquiry/${recordID}`;
    const { data } = await API(APIMethod.GET, endpoint, null);
    handleViewModal(data);
  };

  const getListData = async () => {
    setIsLoading(true);
    const endpoint = `Inquiry/getInquiryData`;
    const { data } = await API(APIMethod.GET, endpoint, null);
    setListData(data);
    setIsLoading(false);
  };

  const deleteClick = useCallback(
    async deletedata => {
      // Here you can view the data and delete through API calling
      var inquiryID = deletedata;
      const endpoint =
        "Inquiry/deleteInquiry?id=" + inquiryID + "&userID=" + LoggedinUserID;
      const { data } = await API(APIMethod.DELETE, endpoint, null);
      toast.success("Inquiry Deleted Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      getListData();
    },
    [listData]
  );

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Name"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Mobile No."
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "mobileNo",
        Cell: tableInstance => {
          return (
            <>
              <div>
                <a href={`tel:${tableInstance.row.original.mobileNo}`}>
                  {tableInstance.row.original.mobileNo}
                </a>
              </div>
            </>
          );
        }
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Email"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "email",
        Cell: tableInstance => {
          return (
            <>
              <div>
                <a href={`mailto:${tableInstance.row.original.email}`}>
                  {tableInstance.row.original.email}
                </a>
              </div>
            </>
          );
        }
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Description"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "description",
        Cell: tableInstance => {
          const description = tableInstance.row.original.description;
          return (
            <>
              <a
                href="javascript:void(0)"
                onClick={e => handleViewModal(tableInstance.row.original)}
              >
                {description.length > 50
                  ? description.substr(0, 50) + "..."
                  : description}
              </a>
            </>
          );
        }
      },
      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Status"
      //       />
      //     );
      //   },
      //   disableSortBy: true,
      //   disableFilters: true,
      //   placeholder: "",
      //   accessor: "isActionTaken",
      //   Cell: tableInstance => {
      //     return (
      //       <>
      //         <div className="react-action-class">
      //           {tableInstance.row.original.isActionTaken ? (
      //             <RoyTooltip
      //               id={"actionTaken-" + tableInstance.row.original.inquiryID}
      //               title={"Change to action not taken"}
      //               placement="left"
      //             >
      //               <div
      //                 id={"actionTaken-" + tableInstance.row.original.inquiryID}
      //               >
      //                 <Button
      //                   style={{ minWidth: "125px" }}
      //                   className="c-btn c-info mr-10"
      //                   onClick={() =>
      //                     actionTakenStatusHandler(tableInstance.row.original)
      //                   }
      //                 >
      //                   <div className="fs-14 medium-text">
      //                     <i className="fas fa-toggle-off mr-6" /> Action Taken
      //                   </div>
      //                 </Button>
      //               </div>
      //             </RoyTooltip>
      //           ) : (
      //             <RoyTooltip
      //               id={
      //                 "actionNotTaken-" + tableInstance.row.original.inquiryID
      //               }
      //               title={"Change to action taken"}
      //               placement="left"
      //             >
      //               <div
      //                 id={
      //                   "actionNotTaken-" + tableInstance.row.original.inquiryID
      //                 }
      //               >
      //                 <Button
      //                   style={{ minWidth: "125px" }}
      //                   className="c-btn c-danger mr-10"
      //                   onClick={() =>
      //                     actionTakenStatusHandler(tableInstance.row.original)
      //                   }
      //                 >
      //                   <div className="fs-14 medium-text">
      //                     <i className="fas fa-toggle-on mr-6" /> Action Not
      //                     Taken
      //                   </div>
      //                 </Button>
      //               </div>
      //             </RoyTooltip>
      //           )}
      //         </div>
      //       </>
      //     );
      //   }
      // },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        disableSortBy: true,
        disableFilters: true,
        placeholder: "",
        accessor: "id",
        Cell: tableInstance => {
          return (
            <>
              <div className="react-action-class wide-cell custom-action-button-royTooltip">
                {candelete && (
                  <RoyTooltip
                    id={"remove-" + tableInstance.row.original.inquiryID}
                    title={"Remove inquiry"}
                    placement="left"
                  >
                    <div id={"remove-" + tableInstance.row.original.inquiryID}>
                      <button
                        className="react-table-delete-button"
                        onClick={() => {
                          setDeleteID(tableInstance.row.original.inquiryID);
                          setWarningAlert(!warningAlert);
                        }}
                      >
                        <i className="fa fa-trash-can"></i>
                      </button>
                    </div>
                  </RoyTooltip>
                )}
              </div>
            </>
          );
        }
      }
    ],
    [deleteClick]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: listData,
      columns: columns,
      initialState: {
        hiddenColumns: [!candelete ? "id" : ""],
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleViewModal = data => {
    setModalData(data);
    setShowModal(true);
  };

  const renderComponent = (
    <>
      <ReactTableWrapper {...props}>
        <SweetAlert
          warning
          showCancel
          show={warningAlert}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => {
            setConfirmDelete(!confirmDelete);
            setWarningAlert(false);
            deleteClick(deleteId);
          }}
          onCancel={() => setWarningAlert(!warningAlert)}
        ></SweetAlert>

        <div className="roe-card-style mt-15 mb-30">
          <div className="roe-card-header module-header">
            <div className="flex-1">
              <span className="hash"># </span> Inquiries
            </div>
          </div>

          <div className="table-container  overflow-auto">
            <table
              border={1}
              className="custom-react-table-theme-class"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(header => (
                      <th
                        {...header.getHeaderProps(
                          header.getSortByToggleProps()
                        )}
                      >
                        <div>{header.render("Header")}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(header => {
                      return (
                        <td>
                          <div>
                            {header.canFilter ? header.render("Filter") : null}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination
            onPageChange={gotoPage}
            pages={pageCount}
            page={pageIndex}
          />
        </div>
      </ReactTableWrapper>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        // backdrop={true}
        // className={className}
      >
        <ModalHeader toggle={() => setShowModal(!showModal)}>
          Inquiry Details
        </ModalHeader>
        <ModalBody>
          {modalData && (
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Name
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.name}
                </label>
              </div>
              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Mobile No.
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.mobileNo}
                </label>
              </div>
              <div className="col-sm-4 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Email
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.email}
                </label>
              </div>
              <div className="col-sm-12 form-group">
                <label className="form-lable w-100 font-weight-bold">
                  Description
                </label>
                <label className="form-lable w-100 text-wrap text-break">
                  {modalData.description}
                </label>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );

  return isLoading ? <LoadingSpinner /> : renderComponent;
};

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

const FilterComponent = tableInstance => {
  const { filterValue, setFilter } = tableInstance.column;
  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="tabl-search react-form-input"
      placeholder={`Search ${tableInstance.column.placeholder}`}
      onClick={e => e.stopPropagation()}
    />
  );
};

export default connect(mapStateToProps)(Inquiry);
