import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../../util/ApiHandler";

const HomepageStatistics = props => {
  const menuName = "homepage";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const CreatedByID = props.authData.userID;

  const history = useHistory();
  const [locations, setLocations] = useState("");
  const [patientsTreated, setPatientsTreated] = useState("");
  const [employees, setEmployees] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    GetHomepageStatisticsData();
  }, []);

  const GetHomepageStatisticsData = async () => {
    setIsLoading(true);
    const endpoint = "WebsiteSettings/getWebsiteSettingsData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    if (data.length > 0 && data[0].homepageStatistics) {
      const homepageStatistics = data[0].homepageStatistics;

      setLocations(homepageStatistics.locations);
      setPatientsTreated(homepageStatistics.patientsTreated);
      setEmployees(homepageStatistics.employees);
      setYearsOfExperience(homepageStatistics.yearsOfExperience);
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    let homepageStatistics = {
      locations: locations ? locations : null,
      patientsTreated: patientsTreated ? patientsTreated : null,
      employees: employees ? employees : null,
      yearsOfExperience: yearsOfExperience ? yearsOfExperience : null
    };
    setIsLoading(true);
    const endpoint = "WebsiteSettings/manageWebsiteSettings";
    const params = {
      homepageStatistics,
      isActive,
      CreatedByID
    };
    const { data } = await API(APIMethod.POST, endpoint, params);
    toast.success("Details Saved Successfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
    GetHomepageStatisticsData();
    setIsLoading(false);
  };

  const renderPage = (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Statistics
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label>Locations</label>
                  <input
                    id="txtLocations"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setLocations(e.target.value)}
                    placeholder="Enter Total Locations"
                    value={locations}
                    maxLength={10}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Patients Treated</label>
                  <input
                    id="txtPatientsTreated"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setPatientsTreated(e.target.value)}
                    placeholder="Enter Total Patients Treated"
                    value={patientsTreated}
                    maxLength={10}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Employees</label>
                  <input
                    id="txtEmployees"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setEmployees(e.target.value)}
                    placeholder="Enter Total Employees"
                    value={employees}
                    maxLength={10}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Years Of Experience</label>
                  <input
                    id="txtlocations"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setYearsOfExperience(e.target.value)}
                    placeholder="Enter Total Years Of Experience"
                    value={yearsOfExperience}
                    maxLength={10}
                  />
                </div>
              </div>

              <div className="rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <LoadingSpinner /> : renderPage;
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(HomepageStatistics);
