import Layout from "layouts/DashboardLayout.jsx";
import {
  Login,
  Error400,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  Error500,
  Preview,
  PreviewBlog,
  PreviewAboutUs
} from "./../views/pages/index";

const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/error400", component: Error400 },
  { path: "/error500", component: Error500 },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/resetPassword", component: ResetPassword },
  { path: "/changePassword", component: ChangePassword },
  { path: "/preview", component: Preview },
  { path: "/preview-blog", component: PreviewBlog },
  { path: "/preview-about-us", component: PreviewAboutUs },
  { path: "/", component: Layout }
];

export default indexRoutes;
