import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { loginBack } from "helper/constant";
import ProfileInfoComponent from "components/profile/ProfileInfoComponent";
import API, { APIMethod } from "../../../util/ApiHandler";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/loader/LoadingSpinner"

const UserProfile = props => {
    const LoggedinUserId = props.authData.userID;

    const {
        handleChange,
        handleBlur,
        errors,
        touched,
        submitCount,
        values
      } = props;


  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [userType, setuserType] = useState();
  const [Image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(() => {
        getUserData();

    },[]);
    const getUserData = async() => {
        setIsLoading(true);
        const endpoint =
            "UserMaster/" + LoggedinUserId;
        const { data } = await API(APIMethod.GET, endpoint, null);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmailAddress(data.emailAddress);
        setImage(data.image);
        setuserType(data.userType)
        values.txtfirstname=data.firstName;
        values.txtlastname=data.lastName;
        setIsLoading(false);

    }

    

    const Back = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    };

    const Error = props => {
        const field1 = props.field;
        if ((errors[field1] && touched[field1]) || submitCount > 0) {
          return (
            <span className={props.class ? props.class : "error-msg"}>
              {errors[field1]}
            </span>
          );
        } else {
          return <span></span>;
        }
      };
    
      const handleSubmit = async () => {
        let { values, isValid, handleSubmit } = props;
        var FirstName = values.txtfirstname;
        var LastName = values.txtlastname;
        if (isValid && FirstName && LastName ) {
          const endpoint = "UserMaster/updateProfile?id=" + LoggedinUserId;
          const params = {
            FirstName,
            LastName,
            updatedByID:LoggedinUserId
          }
          const { data } = await API(APIMethod.PUT, endpoint, params);
          toast.success("Profile Updated Successfully.", {
            position: toast.POSITION.TOP_RIGHT
          });
          setFirstName(data.firstName);
          setLastName(data.lastName);
        }
        handleSubmit();
      };

   
    

    

    return (
        isLoading?<LoadingSpinner></LoadingSpinner>:
        <div className="Profile-component">
            <div style={Back} className="background" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-lg-4 col-xl-3 profile-left-shade">
                        <ProfileInfoComponent role={userType} userImage={Image} LoggedinUserId={LoggedinUserId} />
                    </div>

                    <div className="col-md-12 col-lg-8 col-xl-9 profile-right-shade">
                        <div className="col-12 ptb-15">
                            {/* <div className="roe-card-style"> */}
                            <div className="roe-card-header">

                            </div>

                            <div className="roe-card-body">
                                <div>
                                    <form>

                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input
                                                id="txtfirstname"
                                                type="text"
                                                className="form-control react-form-input"
                                                value={values.txtfirstname}
                                                onChange={handleChange}
                                                placeholder="Enter First Name"
                                                onBlur={handleBlur}
                                                maxLength={50}
                                            />
                                            <Error field="txtfirstname" />
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name</label>

                                            <input
                                                id="txtlastname"
                                                type="text"
                                                value={values.txtlastname}
                                                className="form-control react-form-input"
                                                onChange={handleChange}
                                                placeholder="Enter Last Name"
                                                onBlur={handleBlur}
                                                maxLength={50}
                                            />
                                            <Error field="txtlastname" />
                                        </div>

                                        <div className="form-group">
                                            <label>Email Address</label>

                                            <input
                                                id="txtemailaddress"
                                                type="text"
                                                className="form-control react-form-input"
                                                placeholder="Enter Email Address"
                                                maxLength={200}
                                                value={emailAddress}
                                                disabled
                                            />
                                            {/* <Error field="txtemailaddress" /> */}
                                        </div>





                                        <div className="rightAlignContentDiv">

                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                            onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>

                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>




                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
      authData: {
        token: state.auth.accessToken,
        isLogin: state.auth.isLogin,
        userID: state.auth.userID,
        permission: state.auth.permission
      }
    };
  };
  const enhancer = withFormik({
    validationSchema: Yup.object().shape({
      txtfirstname: Yup.string().required("This Field is required"),
      txtlastname: Yup.string().required("This Field is required"),
    }),
    mapPropsToValues: props => ({
      txtfirstname: '',
      txtlastname: '',
    }),
    handleSubmit: values => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true
  });

export default compose(enhancer, connect(mapStateToProps))(UserProfile);
