import React, { useEffect, useState } from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import {
  friend1,
  friend2,
  friend3,
  people1,
  people2,
  people3,
  ProfileLockScreen
} from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import PopoverBlock from "./PopoverBlock";
import CustomPopoverBlock from "./CustomPopoverBlock";
import API, { APIMethod } from "../../util/ApiHandler";

const { logout } = AuthActions;

const Header = props => {
  const { drawerMiniMethod, mini, layoutSettingDrawerToggle } = props;
  const LoggedinUserId = props.authData.userID;

  const userSignout = () => {
    props.logout();
  };

  const [notificationData, setNotificationData] = useState(null);
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    GetNotificationData();
    GetUserData();
  }, []);

  const GetNotificationData = async () => {
    const endpoint = "Notification/getNotificationData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    setNotificationData(data);
  };
  const GetUserData = async () => {
    const endpoint = "UserMaster/" + LoggedinUserId;
    const { data } = await API(APIMethod.GET, endpoint, null);
    setUserImage(data.image);
  };

  const handleInquiryNotificationClick = async () => {
    // console.log("Inquiry Clicked");
    if (notificationData && notificationData.inquiryCount > 0) {
      const endpoint = "Notification/resetInquiryNotificationRead";
      const { data } = await API(APIMethod.PUT, endpoint, null);
      GetNotificationData();
    }
  };

  const handleRatingReviewNotificationClick = async () => {
    // console.log("RatingReview Clicked");
    if (notificationData && notificationData.ratingReviewCount > 0) {
      const endpoint = "Notification/resetRatingReviewNotificationRead";
      const { data } = await API(APIMethod.PUT, endpoint, null);
      GetNotificationData();
    }
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">{""}</span>
          </div>

          <div className="pl-10">
            <button
              id="mail"
              className="top-header-icon"
              onClick={handleInquiryNotificationClick}
            >
              <i className="far fa-envelope"></i>
              {notificationData != null && notificationData.inquiryCount > 0 && (
                <div className="button-badge fs-11 demi-bold-text">
                  {notificationData.inquiryCount}
                  {/* {notificationData && notificationData.inquiryCount}
                    {!notificationData && 0} */}
                </div>
              )}
            </button>
            <UncontrolledPopover
              placement="bottom-start"
              target="mail"
              className="header-popover"
              trigger="legacy"
            >
              <PopoverBody className="mail-popover-body">
                {notificationData && notificationData.inquiries.length > 0 && (
                  <>
                    {/* <div className="fs-13 bold-text mb-10">
                      You have few inquiries to read.
                    </div> */}
                    {notificationData.inquiries.map(e => {
                      return (
                        <>
                          <CustomPopoverBlock
                            type="inquiry"
                            data={e}
                            key={`NotificationInquiry_${e.inquiryID}`}
                          />
                        </>
                      );
                    })}
                    <CustomPopoverBlock
                      type="inquiryAll"
                      key={`NotificationInquiry_SeeAll`}
                    />
                  </>
                )}
                {((notificationData &&
                  notificationData.inquiries.length == 0) ||
                  !notificationData) && (
                  <>
                    <div className="fs-13 bold-text mb-10">
                      No new inquiries available yet.
                    </div>
                  </>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </div>

          <div className="pl-10 flex-1">
            <button
              id="notification"
              className="top-header-icon"
              onClick={handleRatingReviewNotificationClick}
            >
              <i className="far fa-bell"></i>
              {notificationData != null &&
                notificationData.ratingReviewCount > 0 && (
                  <div className="button-badge fs-11 demi-bold-text">
                    {notificationData.ratingReviewCount}
                  </div>
                )}
            </button>
            <UncontrolledPopover
              placement="bottom-start"
              target="notification"
              className="header-popover"
              trigger="legacy"
            >
              <PopoverBody className="mail-popover-body">
                {notificationData && notificationData.ratingReviews.length > 0 && (
                  <>
                    {/* <div className="fs-13 bold-text mb-10">
                      You have few reviews to read.
                    </div> */}
                    {notificationData.ratingReviews.map(e => {
                      return (
                        <>
                          <CustomPopoverBlock
                            type="ratingReview"
                            data={e}
                            key={`NotificationRatingReview_${e.ratingReviewID}`}
                          />
                        </>
                      );
                    })}
                    <CustomPopoverBlock
                      type="ratingReviewAll"
                      key={`NotificationRatingReview_SeeAll`}
                    />
                  </>
                )}
                {((notificationData &&
                  notificationData.ratingReviews.length == 0) ||
                  !notificationData) && (
                  <>
                    <div className="fs-13 bold-text mb-10">
                      No new reviews available yet.
                    </div>
                  </>
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          <div className="pl-10">
            <div id="profile">
              <img
                className="top-header-profile-class"
                src={userImage ? userImage : ProfileLockScreen}
                alt="notify"
              />
            </div>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/profile")}
                >
                  My Profile
                </div>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/ChangePassword")}
                >
                  Change Password
                </div>

                <div className="roy-menu-list" onClick={userSignout}>
                  Logout
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(Header);
