import React, { useEffect, useState } from "react";
import { AWS_SDK, variables } from "Variables.js";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const ContactUsPage = props => {
  const menuName = "staticpages";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const CreatedByID = props.authData.userID;

  const history = useHistory();
  const [bannerImage, setBannerImage] = useState("");
  const [initialBannerImage, setInitialBannerImage] = useState("");
  const [bannerInput, setBannerInput] = useState(null);

  const [location, setLocation] = useState("");
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [footerMessage, setFooterMessage] = useState("");
  const [mapURL, setMapURL] = useState("");
  const [socialFacebook, setSocialFacebook] = useState("");
  const [socialTwitter, setSocialTwitter] = useState("");
  const [socialLinkedIn, setSocialLinkedIn] = useState("");
  const [socialInstagram, setSocialInstagram] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const key = AWSS3BucketKey.staticpagesBanner;

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    GetStaticPageData();
  }, []);

  const GetStaticPageData = async () => {
    setIsLoading(true);
    const endpoint = "WebsiteSettings/getWebsiteSettingsData";
    const { data } = await API(APIMethod.GET, endpoint, null);

    if (data.length > 0 && data[0].contactSetting) {
      const contactSetting = data[0].contactSetting;

      setBannerImage(contactSetting.bannerImage);
      setInitialBannerImage(contactSetting.bannerImage);
      setLocation(contactSetting.location);
      setEmail1(contactSetting.email[0] ?? "");
      setEmail2(contactSetting.email[1] ?? "");
      setPhone1(contactSetting.mobileNo[0] ?? "");
      setPhone2(contactSetting.mobileNo[1] ?? "");
      setFooterMessage(contactSetting.footerMessage);
      setMapURL(contactSetting.mapURL);
      setSocialFacebook(contactSetting.socialFacebook);
      setSocialTwitter(contactSetting.socialTwitter);
      setSocialLinkedIn(contactSetting.socialLinkedIn);
      setSocialInstagram(contactSetting.socialInstagram);
    }
    setIsLoading(false);
  };

  function handleBannerImage(e) {
    if (e.target.files.length > 0) {
      setBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBannerInput(null);
      setBannerImage(initialBannerImage);
    }
  }

  const handleSubmit = async () => {
    let email = [];
    if (email1) {
      email.push(email1);
    }
    if (email2) {
      email.push(email2);
    }

    let mobileNo = [];
    if (phone1) {
      mobileNo.push(phone1);
    }
    if (phone2) {
      mobileNo.push(phone2);
    }

    setIsLoading(true);

    let bannerLocation = bannerImage != "" ? bannerImage : null;

    if (bannerInput) {
      const ext = bannerInput.name.substring(
        bannerInput.name.lastIndexOf(".") + 1
      );
      const uploadKey = key + (generate_uuidv4() + "." + ext);
      let bucket = AWS_SDK.bucket;

      //Remove existing file from AWS
      if (
        initialBannerImage &&
        initialBannerImage.includes(
          `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
        )
      ) {
        await s3DeleteFromURL(initialBannerImage);
      }
      bannerLocation = await UploadFile(bannerInput, bucket, uploadKey);
    }

    let contactSetting = {
      bannerImage: bannerLocation,
      location: location ? location : null,
      email: email,
      mobileNo: mobileNo,
      footerMessage: footerMessage ? footerMessage : null,
      mapURL: mapURL ? mapURL : null,
      socialFacebook: socialFacebook ? socialFacebook : null,
      socialTwitter: socialTwitter ? socialTwitter : null,
      socialLinkedIn: socialLinkedIn ? socialLinkedIn : null,
      socialInstagram: socialInstagram ? socialInstagram : null
    };

    const endpoint = "WebsiteSettings/manageWebsiteSettings";
    const params = {
      contactSetting,
      isActive,
      CreatedByID
    };
    const { data } = await API(APIMethod.POST, endpoint, params);
    toast.success("Details Saved Successfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
    GetStaticPageData();
    setIsLoading(false);
  };

  const renderPage = (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Contact Us
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <label>Banner Image</label>
                  <br />
                  <input
                    type="file"
                    id="fuBannerImage"
                    onChange={handleBannerImage}
                    style={{ display: "none" }}
                  />
                  <label
                    className="btn btn-primary"
                    for="fuBannerImage"
                    style={{ color: "white" }}
                  >
                    <i className="fa fa-upload" />{" "}
                    {bannerImage ? "Change Image" : "Upload Image"}{" "}
                  </label>
                  {bannerImage && (
                    <>
                      <br />
                      <br />
                      <img src={bannerImage} style={{ height: "200px" }}></img>
                    </>
                  )}
                </div>

                <div className="col-sm-12 form-group">
                  <label>Location</label>
                  <input
                    id="txtLocation"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setLocation(e.target.value)}
                    placeholder="Enter Location"
                    value={location}
                    maxLength={250}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Email</label>
                  <input
                    id="txtEmail1"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setEmail1(e.target.value)}
                    placeholder="Enter Email"
                    value={email1}
                    maxLength={150}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Alternate Email</label>
                  <input
                    id="txtEmail2"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setEmail2(e.target.value)}
                    placeholder="Enter Alternate Email"
                    value={email2}
                    maxLength={150}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Phone</label>
                  <input
                    id="txtPhone1"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setPhone1(e.target.value)}
                    placeholder="Enter Phone"
                    value={phone1}
                    maxLength={20}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Alternate Phone</label>
                  <input
                    id="txtPhone2"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setPhone2(e.target.value)}
                    placeholder="Enter Alternate Phone"
                    value={phone2}
                    maxLength={20}
                  />
                </div>

                <div className="col-sm-12 form-group">
                  <label>Footer Message</label>
                  <input
                    id="txtFooterMessage"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setFooterMessage(e.target.value)}
                    placeholder="Enter Footer Message"
                    value={footerMessage}
                    maxLength={500}
                  />
                </div>

                <div className="col-sm-12 form-group">
                  <label>Google Map URL</label>
                  <input
                    id="txtMapURL"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMapURL(e.target.value)}
                    placeholder="Enter Google Map URL"
                    value={mapURL}
                    maxLength={1000}
                  />
                </div>

                <div className="col-sm-12 form-group">
                  <div className="roe-card-header px-0">
                    <span className="hash"># </span>Social Media
                  </div>
                </div>

                <div className="col-sm-6 form-group">
                  <label>Facebook URL</label>
                  <input
                    id="txtSocialFacebook"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setSocialFacebook(e.target.value)}
                    placeholder="Enter Facebook URL"
                    value={socialFacebook}
                    maxLength={1000}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Twitter URL</label>
                  <input
                    id="txtSocialTwitter"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setSocialTwitter(e.target.value)}
                    placeholder="Enter Twitter URL"
                    value={socialTwitter}
                    maxLength={1000}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>LinkedIn URL</label>
                  <input
                    id="txtSocialLinkedIn"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setSocialLinkedIn(e.target.value)}
                    placeholder="Enter LinkedIn URL"
                    value={socialLinkedIn}
                    maxLength={1000}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <label>Instagram URL</label>
                  <input
                    id="txtSocialInstagram"
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setSocialInstagram(e.target.value)}
                    placeholder="Enter Instagram URL"
                    value={socialInstagram}
                    maxLength={1000}
                  />
                </div>
              </div>

              <div className="rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <LoadingSpinner /> : renderPage;
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(ContactUsPage);
