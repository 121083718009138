import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../../reacttbl.style";
import Button from "components/button/Button";
import { useHistory } from "react-router-dom";
import { AWS_SDK, variables } from "Variables.js";
import SweetAlert from "react-bootstrap-sweetalert";
import RoyTooltip from "components/common/RoyTooltip";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { s3DeleteFromURL } from "util/AWSS3Upload";
import API, { APIMethod } from "../../../../util/ApiHandler";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const SubCategory = props => {
  const menuName = "categories";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const LoggedinUserID = props.authData.userID;

  const history = useHistory();
  const [dummyData, setDummyData] = useState([]);
  const [warningAlert, setWarningAlert] = useState(false);
  const [deleteId, setDeleteID] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    getData();
  }, []);
  const getData = async () => {
    setIsLoading(true);
    const endpoint = "SubCategoryMaster/getSubCategoryData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.forEach((el, index) => {
      const regex = /(<([^>]+)>)/gi;
      data[index].shortDescription = el.description.replace(regex, "");
      data[index].shortDescription =
        data[index].shortDescription.length > 50
          ? data[index].shortDescription.substr(0, 50) + "..."
          : data[index].shortDescription;
    });
    setDummyData(data);
    setIsLoading(false);
  };
  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      localStorage.clear();
      history.push("/createsubcategory");
    }
  };

  const deleteClick = useCallback(
    async deletedata => {
      // Here you can view the data and delete through API calling
      var subcategoryId = deletedata;
      const endpoint =
        "SubCategoryMaster/deleteSubCategory?id=" +
        subcategoryId +
        "&userID=" +
        LoggedinUserID;
      const { data } = await API(APIMethod.DELETE, endpoint, null);
      const image = data.bannerImage;
      if (
        image &&
        image.includes(`https://${AWS_SDK.bucket}.s3.amazonaws.com/`)
      ) {
        s3DeleteFromURL(image);
      }

      getData();
      toast.success("Sub-Category Deleted Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
    },
    [dummyData]
  );
  const activeInactiveStatusHandler = async statusdata => {
    let { subCategoryID, isActive } = statusdata;
    const endpoint = "SubCategoryMaster/UpdatestatusOfSubCategory";
    const params = {
      subCategoryID,
      isActive
    };
    const { data } = await API(APIMethod.PUT, endpoint, params);
    getData();
    toast.success("Status Changed Successfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const setData = data => {
    let {
      subCategoryID,
      categoryID,
      subCategoryName,
      description,
      isActive,
      shortDescription,
      subCategoryLogo,
      bannerImage,
      seoMetaTitle,
      seoMetaDescription
    } = data;
    localStorage.setItem("ID", subCategoryID);
    localStorage.setItem("CategoryID", categoryID);
    localStorage.setItem("SubCategoryName", subCategoryName);
    localStorage.setItem("seoMetaTitle", seoMetaTitle);
    localStorage.setItem("seoMetaDescription", seoMetaDescription);
    localStorage.setItem("ShortDescription", shortDescription);
    localStorage.setItem("Description", description);
    localStorage.setItem("IsActive", isActive);
    localStorage.setItem("SubCategoryLogo", subCategoryLogo);
    localStorage.setItem("BannerImage", bannerImage ?? "");
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Category Name"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "categoryName"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Sub-Category Name"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "subCategoryName"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Short Description"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "shortDescription"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Status"
            />
          );
        },
        disableSortBy: true,
        disableFilters: true,
        placeholder: "",
        accessor: "isActive",
        Cell: tableInstance => (
          <div className="react-action-class">
            {tableInstance.row.original.isActive ? (
              canUpdate ? (
                <RoyTooltip
                  id={"active-" + tableInstance.row.original.subCategoryID}
                  title={"Click to Deactivate"}
                  placement="left"
                >
                  <div
                    id={"active-" + tableInstance.row.original.subCategoryID}
                  >
                    <Button
                      style={{ minWidth: "125px" }}
                      className="c-btn c-info mr-10"
                      onClick={() =>
                        activeInactiveStatusHandler(tableInstance.row.original)
                      }
                    >
                      <div className="fs-14 medium-text">
                        <i className="fas fa-toggle-off mr-6" /> Active
                      </div>
                    </Button>
                  </div>
                </RoyTooltip>
              ) : (
                <div id={"active-" + tableInstance.row.original.subCategoryID}>
                  <label
                    style={{ minWidth: "125px" }}
                    className="c-btn c-info mr-10"
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </label>
                </div>
              )
            ) : canUpdate ? (
              <RoyTooltip
                id={"deactive-" + tableInstance.row.original.subCategoryID}
                title={"Click to Active"}
                placement="left"
              >
                <div
                  id={"deactive-" + tableInstance.row.original.subCategoryID}
                >
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-danger mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(tableInstance.row.original)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                  </Button>
                </div>
              </RoyTooltip>
            ) : (
              <div id={"deactive-" + tableInstance.row.original.subCategoryID}>
                <label
                  style={{ minWidth: "125px" }}
                  className="c-btn c-danger mr-10"
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-on mr-6" /> InActive
                  </div>
                </label>
              </div>
            )}
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              {canUpdate && (
                <button
                  className="react-table-edit-button"
                  onClick={() => editClick(tableInstance.row.original)}
                >
                  <i className="fas fa-edit" />
                </button>
              )}
              {candelete && (
                <button
                  className="react-table-delete-button"
                  onClick={() => {
                    setDeleteID(tableInstance.row.original.subCategoryID);
                    setWarningAlert(!warningAlert);
                  }}
                  //onClick={() => deleteClick(tableInstance.row.original)}
                >
                  <i className="fas fa-trash" />
                </button>
              )}
              {canAdd && (
                <button
                  title="Add Sub-Sub-Category"
                  className="react-table-delete-button"
                  onClick={() => {
                    AddSubSubCategory(tableInstance.row.original.subCategoryID);
                  }}
                >
                  <i className="fas fa-plus" />
                </button>
              )}
            </div>
          );
        }
      }
    ],
    [deleteClick]
  );

  const editClick = async editdata => {
    // Here you can view the data and make forward action for edit data
    const endpoint = "SubCategoryMaster/" + editdata.subCategoryID;
    const { data } = await API(APIMethod.GET, endpoint, null);
    setData(data);
    history.push("/updatesubcategory");
  };
  const AddSubSubCategory = data => {
    localStorage.setItem("SubCategoryID", data);
    history.push("/CreateSubSubCategory");
  };
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: dummyData,
      columns: columns,
      initialState: {
        hiddenColumns: [!canUpdate && !candelete ? "id" : ""],
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ReactTableWrapper {...props}>
      <div className="roe-card-style mt-15 mb-30">
        <SweetAlert
          warning
          showCancel
          show={warningAlert}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => {
            setConfirmDelete(!confirmDelete);
            setWarningAlert(false);
            deleteClick(deleteId);
          }}
          onCancel={() => setWarningAlert(!warningAlert)}
        ></SweetAlert>
        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Sub-Categories
          </div>

          <div className="mr-10">
            {canAdd && (
              <Button
                className="c-btn ma-5 c-primary"
                onClick={() => formAction("add")}
              >
                <i className="fas fa-plus mr-10" />
                Add Sub-Category
              </Button>
            )}
          </div>
        </div>
        <div className="table-container  overflow-auto">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => {
                    return (
                      <td>
                        <div>
                          {header.canFilter ? header.render("Filter") : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          onPageChange={gotoPage}
          pages={pageCount}
          page={pageIndex}
        />
      </div>
    </ReactTableWrapper>
  );
};

const FilterComponent = tableInstance => {
  const { filterValue, setFilter } = tableInstance.column;
  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="tabl-search react-form-input"
      placeholder={`Search ${tableInstance.column.placeholder}`}
      onClick={e => e.stopPropagation()}
    />
  );
};

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(SubCategory);
