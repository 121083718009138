import React, { useState } from "react";
import { loginBack, ForgotIcon } from "helper/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../util/ApiHandler";

const ForgotPassword = () => {
  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const history = useHistory();

  const [email, setEmail] = useState();
  const handleForgotPassword = async e => {
    e.preventDefault();
    const endpoint = "UserMaster/forgotPassword?emailId=" + email;
    const { data } = await API(APIMethod.POST, endpoint, null);
    toast.success("Link sent to email successfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
    history.push("/Login");
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form className="pa-24" onSubmit={handleForgotPassword}>
          <div className="form-group">
            <input
              type="email"
              className="form-control react-form-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <button type="submit" className="btn form-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
