import React, { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import PageBanner from "./PageBanner";
import TopHeader from "./TopHeader";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { DateFormat_1 } from "helper/methods";
import "assets/scss/preview-page.scss";

const Preview = props => {
  const [title, setTitle] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [description, setDescription] = useState();
  const [nameOfAuther, setNameOfAuther] = useState();
  const [bannerImage, setBannerImage] = useState();

  useEffect(() => {
    setTitle(localStorage.getItem("PreviewTitle"));
    setShortDescription(localStorage.getItem("PreviewShortDescription"));
    setDescription(localStorage.getItem("PreviewDescription"));
    setNameOfAuther(localStorage.getItem("PreviewNameOfAuther"));
    setBannerImage(localStorage.getItem("PreviewBanner"));

    localStorage.removeItem("PreviewTitle");
    localStorage.removeItem("PreviewShortDescription");
    localStorage.removeItem("PreviewDescription");
    localStorage.removeItem("PreviewNameOfAuther");
    localStorage.removeItem("PreviewBanner");
  }, []);

  return (
    <>
      <TopHeader />
      <Navbar />

      <div style={{ height: "100vh", overflow: "auto" }}>
        <PageBanner
          pageTitle={
            title ? title : "" // "Service"
          }
          bgImage="page-title-one"
          bgURL={
            bannerImage && bannerImage != "null" && bannerImage != ""
              ? bannerImage
              : null
          }
        />

        <div className="blog-details-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-details-item">
                  {!description && (
                    <div className="text-center">
                      <h2>Information not available at the moment.</h2>
                    </div>
                  )}
                  {description && (
                    <>
                      <div className="blog-details-img">
                        <ul>
                          <li>
                            <i className="icofont-businessman"></i>{" "}
                            {nameOfAuther}
                          </li>
                          <li>
                            <i className="icofont-calendar"></i>
                            {DateFormat_1(new Date())}
                          </li>
                        </ul>
                      </div>
                      <div className="dynamic-Content">
                        {HTMLReactParser(description)}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog-details-item">
                  <div className="blog-details-recent">
                    <h3>Recent Blogs</h3>
                    <ul>
                      <li>
                        <img src="/images/default-blog.jpg" alt="Recent Blog" />
                        <a style={{ minHeight: "50px" }}>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit
                        </a>
                        <ul>
                          <li>
                            <i className="icofont-businessman"></i> Lorem ipsum
                          </li>
                          <li>
                            <i className="icofont-calendar"></i>{" "}
                            {DateFormat_1(new Date())}
                          </li>
                        </ul>
                      </li>

                      <li>
                        <img src="/images/default-blog.jpg" alt="Recent Blog" />
                        <a style={{ minHeight: "50px" }}>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit
                        </a>
                        <ul>
                          <li>
                            <i className="icofont-businessman"></i> Lorem ipsum
                          </li>
                          <li>
                            <i className="icofont-calendar"></i>{" "}
                            {DateFormat_1(new Date())}
                          </li>
                        </ul>
                      </li>

                      <li>
                        <img src="/images/default-blog.jpg" alt="Recent Blog" />
                        <a style={{ minHeight: "50px" }}>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit
                        </a>
                        <ul>
                          <li>
                            <i className="icofont-businessman"></i> Lorem ipsum
                          </li>
                          <li>
                            <i className="icofont-calendar"></i>{" "}
                            {DateFormat_1(new Date())}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Preview;
