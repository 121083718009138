import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const UpdateSubFacility = props => {
  const menuName = "facilities";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const UpdatedByID = props.authData.userID;

  const [facilityOption, setFacilityOption] = useState([]);
  const [facilityValue, setFacilityValue] = useState(undefined);
  const [address, setAddress] = useState(
    localStorage?.getItem("subFacilityAddress") || ""
  );
  const [addressUrl, setAddressUrl] = useState(
    localStorage?.getItem("subFacilityAddressMapURL") || ""
  );
  const facilityValues = [];
  const facilityData = [];

  const key = AWSS3BucketKey.facilitiesBanner;

  useEffect(() => {
    if (!canUpdate) {
      history.push("/subfacility");
    }
    if (facilityOption.length == 0) {
      bindFacility();
    }
  });
  const bindFacility = async () => {
    const endpoint = "Facility/getFacilityData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.forEach((item, i) => {
      if (item.facilityID === localStorage.getItem("FacilityID")) {
        facilityValues.push({
          value: item.facilityID,
          label: item.facilityName
        });
      }
      facilityData.push({
        value: item.facilityID,
        label: item.facilityName
      });
    });
    setFacilityValue(facilityValues);
    setFacilityOption(facilityData);
  };

  const history = useHistory();

  const [facilityID, setFacilityID] = useState(
    localStorage.getItem("FacilityID")
  );
  const [id, setID] = useState(localStorage.getItem("ID"));
  const [subFacilityName, setSubFacilityName] = useState(
    localStorage.getItem("SubFacilityName")
  );
  const [shortDescription, setShortDescription] = useState(
    localStorage.getItem("ShortDescription")
  );
  const [metaTitle, setMetaTitle] = useState(
    localStorage.getItem("seoMetaTitle")
  );
  const [metaDescription, setMetaDescription] = useState(
    localStorage.getItem("seoMetaDescription") || ""
  );
  const [description, setDescription] = useState(
    localStorage.getItem("Description")
  );
  const [isRedirect, setIsRedirect] = useState(
    localStorage.getItem("IsRedirect") == "false" ? false : true
  );
  const [redirectURL, setRedirectURL] = useState(
    localStorage.getItem("RedirectURL")
  );
  const [subFacilityLogo, setSubFacilityLogo] = useState(
    localStorage.getItem("SubFacilityLogo")
  );
  const [bannerImage, setBannerImage] = useState(
    localStorage.getItem("BannerImage")
  );
  const [initialBannerImage, setInitialBannerImage] = useState(
    localStorage.getItem("BannerImage")
  );
  const [bannerInput, setBannerInput] = useState(null);

  const [isActive, setIsActive] = useState(
    localStorage.getItem("IsActive") == "false" ? false : true
  );

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values
  } = props;

  const handleFacilityChange = selectedOption => {
    setFacilityValue(selectedOption);
    setFacilityID(selectedOption.value);
  };

  const handleIsRedirect = () => {
    setIsRedirect(prev => !prev);
  };

  function handleBannerImage(e) {
    if (e.target.files.length > 0) {
      setBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBannerInput(null);
      setBannerImage(initialBannerImage);
    }
  }

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"]
    ]
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };
  const showPreview = () => {
    let { values } = props;
    localStorage.setItem("PreviewTitle", values.txtSubFacilityName);
    localStorage.setItem("PreviewBanner", bannerImage);
    localStorage.setItem("PreviewDescription", description);
    window.open("/preview", "_blank", "noopener,noreferrer");
  };

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit } = props;
    var subFacilityName = values.txtSubFacilityName;
    if (facilityID) {
      if (isValid && subFacilityName) {
        let bannerLocation = bannerImage != "" ? bannerImage : null;

        if (bannerInput) {
          const ext = bannerInput.name.substring(
            bannerInput.name.lastIndexOf(".") + 1
          );
          const uploadKey = key + (generate_uuidv4() + "." + ext);
          let bucket = AWS_SDK.bucket;

          //Remove existing file from AWS
          if (
            initialBannerImage &&
            initialBannerImage.includes(
              `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
            )
          ) {
            await s3DeleteFromURL(initialBannerImage);
          }
          bannerLocation = await UploadFile(bannerInput, bucket, uploadKey);
        }

        const endpoint = "SubFacility/" + id;
        const params = {
          facilityID,
          subFacilityName,
          shortDescription,
          description,
          isRedirect,
          redirectURL,
          subFacilityLogo,
          bannerImage: bannerLocation,
          isActive,
          UpdatedByID,
          SeoMetaTitle: metaTitle,
          SeoMetaDescription: metaDescription,
          SubFacilityAddress: address,
          SubFacilityAddressMapURL: addressUrl
        };
        const { data } = await API(APIMethod.PUT, endpoint, params);
        toast.success("Sub-Facility Updated Successfully.", {
          position: toast.POSITION.TOP_RIGHT
        });
        history.push("/subfacility");
      }
    } else {
      toast.error("Please Select facility.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    handleSubmit();
  };

  const cancel = () => {
    history.push("/subfacility");
  };

  const styles = {
    // this one works
    indicatorsContainer: provided => ({
      ...provided,
      background: "lightgray"
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "black" // Custom colour
    })
  };

  return (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Update Sub-Facility
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                {/* <div className="roe-card-description">Facility</div> */}
                <label>Facility</label>
                <Select
                  placeholder="Select Facility"
                  onChange={handleFacilityChange}
                  options={facilityOption}
                  styles={styles}
                  value={facilityValue}
                />
              </div>
              <div className="form-group">
                <label>Sub-Facility Name</label>
                <input
                  id="txtSubFacilityName"
                  type="text"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  //onChange={(e) => setSubFacilityName(e.target.value)}
                  placeholder="Enter Sub-Facility"
                  onBlur={handleBlur}
                  value={values.txtSubFacilityName}
                  maxLength={50}
                />
                <Error field="txtSubFacilityName" />
              </div>

              <div className="form-group">
                <label>Short Description</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setShortDescription(e.target.value)}
                  placeholder="Enter Short Description"
                  value={shortDescription}
                  maxLength={100}
                />
              </div>

              <div className="form-group">
                <div className="pretty p-svg p-curve">
                  <input
                    type="checkbox"
                    checked={isRedirect}
                    onChange={() => handleIsRedirect(prev => !prev)}
                  />
                  <div className="state p-success">
                    {/* <!-- svg path --> */}
                    <svg className="svg svg-icon" viewBox="0 0 20 20">
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: "white", fill: "white" }}
                      ></path>
                    </svg>
                    <label>Redirect to URL?</label>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Redirect URL</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setRedirectURL(e.target.value)}
                  placeholder="Enter Redirect URL"
                  disabled={!isRedirect}
                  value={redirectURL}
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={setDescription}
                  readOnly={isRedirect}
                  value={description}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  id="SubFacilityAddress"
                  value={address}
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setAddress(e?.target?.value)}
                  placeholder="Enter Address"
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label>Address Map URL</label>
                <input
                  id="SubFacilityAddressMapURL"
                  value={addressUrl}
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setAddressUrl(e?.target?.value)}
                  placeholder="Enter Address Map URL"
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label>Sub-Facility Icon</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setSubFacilityLogo(e.target.value)}
                  placeholder="Enter Sub-Facility Icon"
                  value={subFacilityLogo}
                />
                <span style={{ color: "gray" }}>e.g: fa fa-pencil</span>
              </div>

              <div className="form-group">
                <label>Sub-Facility Banner Image</label>
                <br />
                <input
                  type="file"
                  id="fuBannerImage"
                  onChange={handleBannerImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuBannerImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {bannerImage && bannerImage != "" && bannerImage != "null"
                    ? "Change Image"
                    : "Upload Image"}{" "}
                </label>
                {bannerImage && bannerImage != "" && bannerImage != "null" && (
                  <>
                    <br />
                    <br />
                    <img src={bannerImage} style={{ height: "200px" }}></img>
                  </>
                )}
              </div>

              <div className="form-group">
                <div className="pretty p-svg p-curve">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(prev => !prev)}
                  />
                  <div className="state p-success">
                    {/* <!-- svg path --> */}
                    <svg className="svg svg-icon" viewBox="0 0 20 20">
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: "white", fill: "white" }}
                      ></path>
                    </svg>
                    <label>Active</label>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="roe-card-header pt-0 pl-0 pr-0">SEO</div>
                <div className="form-group">
                  <label>Meta Title</label>
                  <input
                    id="SeoMetaTitle"
                    value={metaTitle}
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMetaTitle(e?.target?.value)}
                    placeholder="Enter Meta Title"
                    onBlur={handleBlur}
                  />
                  {/* <Error field="seoMetaTitle" /> */}
                </div>
                <div className="form-group">
                  <label>Meta Description</label>
                  <input
                    id="SeoMetaDescription"
                    value={metaDescription}
                    type="text"
                    className="form-control react-form-input"
                    onChange={e => setMetaDescription(e?.target?.value)}
                    placeholder="Enter Meta Description"
                    onBlur={handleBlur}
                  />
                  {/* <Error field="seoMetaTitle" /> */}
                </div>
              </div>
              <div className="rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={showPreview}
                >
                  Preview
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    txtSubFacilityName: Yup.string().required("This Field is required")
  }),
  mapPropsToValues: props => ({
    txtSubFacilityName: localStorage.getItem("SubFacilityName")
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default compose(enhancer, connect(mapStateToProps))(UpdateSubFacility);
