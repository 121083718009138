import axios from "axios";
// import { API_URL } from "Variables.js";
import { AWS_SDK, variables } from "Variables.js";
import { store } from "redux/store";

export const APIMethod = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch"
};

const API = (method, apiURL, params) => {
  const url = `${variables.API_URL}${apiURL}`;
  const headers = {};

  if (store.getState().auth.accessToken) {
    headers["Authorization"] = "Bearer " + store.getState().auth.accessToken;
  }

  let axiosObj = {
    method: method,
    url: url,
    data: params,
    headers: headers
  };

  return axios(axiosObj);
};

export default API;
