import { Dashboard } from "views/app/index";
//  PAGES

import { UserProfile } from "views/pages/index";
import { HomepageBanner } from "views/pages/index";
import { CreateHomepageBanner } from "views/pages/index";
import { UpdateHomepageBanner } from "views/pages/index";
import { HomepageStatistics } from "views/pages/index";
import { CategoryBanner } from "views/pages/index";
import { Category } from "views/pages/index";
import { CreateCategory } from "views/pages/index";
import { UpdateCategory } from "views/pages/index";
import { subcategory } from "views/pages/index";
import { CreateSubCategory } from "views/pages/index";
import { UpdateSubCategory } from "views/pages/index";
import { SubSubcategory } from "views/pages/index";
import { CreateSubSubCategory } from "views/pages/index";
import { UpdateSubSubCategory } from "views/pages/index";
import { MapCategoryFacility } from "views/pages/index";
import { FacilityBanner } from "views/pages/index";
import { Facility } from "views/pages/index";
import { CreateFacility } from "views/pages/index";
import { UpdateFacility } from "views/pages/index";
import { SubFacility } from "views/pages/index";
import { CreateSubFacility } from "views/pages/index";
import { UpdateSubFacility } from "views/pages/index";
import { BlogBanner } from "views/pages/index";
import { blog } from "views/pages/index";
import { CreateBlog } from "views/pages/index";
import { UpdateBlog } from "views/pages/index";
import { News } from "views/pages/index";
import { CreateNews } from "views/pages/index";
import { UpdateNews } from "views/pages/index";
import { NewsBanner } from "views/pages/index";
import { StaticPages } from "views/pages/index";
import { UpdateStaticPage } from "views/pages/index";
import { RatingReview } from "views/pages/index";
import { Inquiry } from "views/pages/index";
import { UserMaster } from "views/pages/index";
import { CreateUser } from "views/pages/index";
import { UpdateUser } from "views/pages/index";
import { UserRights } from "views/pages/index";
import { ContactUsPage } from "views/pages/index";

const dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },
  // page route
  { path: "/profile", component: UserProfile },
  { path: "/homepagebanner", component: HomepageBanner },
  { path: "/createhomepagebanner", component: CreateHomepageBanner },
  { path: "/updatehomepagebanner", component: UpdateHomepageBanner },
  { path: "/homepagestatistics", component: HomepageStatistics },
  { path: "/category-banner", component: CategoryBanner },
  { path: "/category", component: Category },
  { path: "/createcategory", component: CreateCategory },
  { path: "/updatecategory", component: UpdateCategory },
  { path: "/subcategory", component: subcategory },
  { path: "/createSubcategory", component: CreateSubCategory },
  { path: "/updatesubcategory", component: UpdateSubCategory },
  { path: "/subsubcategory", component: SubSubcategory },
  { path: "/createSubSubcategory", component: CreateSubSubCategory },
  { path: "/updatesubsubcategory", component: UpdateSubSubCategory },
  { path: "/mapcategoryfacility", component: MapCategoryFacility },
  { path: "/facility-banner", component: FacilityBanner },
  { path: "/facility", component: Facility },
  { path: "/createfacility", component: CreateFacility },
  { path: "/updatefacility", component: UpdateFacility },
  { path: "/subfacility", component: SubFacility },
  { path: "/createsubfacility", component: CreateSubFacility },
  { path: "/updatesubfacility", component: UpdateSubFacility },
  { path: "/blog-banner", component: BlogBanner },
  { path: "/blog", component: blog },
  { path: "/createblog", component: CreateBlog },
  { path: "/updateblog", component: UpdateBlog },
  { path: "/news", component: News },
  { path: "/createnews", component: CreateNews },
  { path: "/updatenews", component: UpdateNews },
  { path: "/news-banner", component: NewsBanner },
  { path: "/staticpages", component: StaticPages },
  { path: "/updatestaticpage", component: UpdateStaticPage },
  { path: "/rating-review", component: RatingReview },
  { path: "/inquiry", component: Inquiry },
  { path: "/contactUsPage", component: ContactUsPage },
  { path: "/usermaster", component: UserMaster },
  { path: "/createuser", component: CreateUser },
  { path: "/updateuser", component: UpdateUser },
  { path: "/userrights", component: UserRights }
];

export default dashboardRoutes;
