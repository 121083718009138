import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { UploadFile } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const CreateHomepageBanner = props => {
  const menuName = "homepage";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const CreatedByID = props.authData.userID;

  const history = useHistory();
  const [bannerTitle, setBannerTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerInput, setBannerInput] = useState(null);

  const [isActive, setIsActive] = useState(true);
  const { handleChange, handleBlur, errors, touched, submitCount } = props;
  const [isLoading, setIsLoading] = useState(false);

  const key = AWSS3BucketKey.homepagebanner;

  useEffect(() => {
    if (!canAdd) {
      history.push("/homepagebanner");
    }
  }, []);

  function handleBannerImage(e) {
    if (e.target.files.length > 0) {
      setBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBannerInput(null);
      setBannerImage("");
    }
  }

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"]
    ]
  };

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit, submitCount, errors } = props;
    var bannerTitle = values.txtBannerTitle;
    if (isValid && bannerTitle) {
      setIsLoading(true);
      let bannerLocation = bannerImage != "" ? bannerImage : null;

      if (bannerInput) {
        const ext = bannerInput.name.substring(
          bannerInput.name.lastIndexOf(".") + 1
        );
        const uploadKey = key + (generate_uuidv4() + "." + ext);
        let bucket = AWS_SDK.bucket;

        bannerLocation = await UploadFile(bannerInput, bucket, uploadKey);
      }

      const endpoint = "HomepageBanner/registerHomepageBanner";
      const params = {
        bannerTitle,
        description,
        bannerImage: bannerLocation,
        isActive,
        CreatedByID
      };
      const { data } = await API(APIMethod.POST, endpoint, params);
      setIsLoading(false);
      toast.success("Banner Added Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      history.push("/homepagebanner");
    }
    handleSubmit();
  };

  const cancel = () => {
    history.push("/homepagebanner");
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Create Homepage Banner
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                <label>Banner Title</label>
                <input
                  id="txtBannerTitle"
                  type="text"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  // onChange={(e) => setBannerTitle(e.target.value)}
                  placeholder="Enter Banner Title"
                  onBlur={handleBlur}
                  maxLength={50}
                />
                <Error field="txtBannerTitle" />
              </div>

              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  onChange={e => setDescription(e.target.value)}
                  placeholder="Enter Description"
                  required={true}
                  maxLength={100}
                />
              </div>

              <div className="form-group">
                <label>Banner Image</label>
                <br />
                <input
                  type="file"
                  id="fuBannerImage"
                  onChange={handleBannerImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuBannerImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {bannerImage ? "Change Image" : "Upload Image"}{" "}
                </label>
                {bannerImage && (
                  <>
                    <br />
                    <br />
                    <img src={bannerImage} style={{ height: "200px" }}></img>
                  </>
                )}
              </div>

              <div className="form-group">
                <div className="pretty p-svg p-curve">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(prev => !prev)}
                  />
                  <div className="state p-success">
                    {/* <!-- svg path --> */}
                    <svg className="svg svg-icon" viewBox="0 0 20 20">
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: "white", fill: "white" }}
                      ></path>
                    </svg>
                    <label>Active</label>
                  </div>
                </div>
              </div>

              <div className="rightAlignContentDiv">
                {canAdd && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    txtBannerTitle: Yup.string().required("This Field is required")
  }),
  mapPropsToValues: props => ({
    txtBannerTitle: props.data ? props.data.txtBannerTitle : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default compose(
  enhancer,
  connect(mapStateToProps)
)(CreateHomepageBanner);
