import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
const MapFacility = ({
  subFacilityData,
  selectedSubFacilityData,
  setSelectedSubFacilityData,
  handleSubmit,
  authData
}) => {
  const menuName = "categories";
  const permissions = authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;

  const history = useHistory();
  const [facilityData, setFacilityData] = useState([]);

  useEffect(() => {
    if (!canUpdate) {
      history.push("/dashboard");
    }
    getFacilityDataFromProps();
  }, []);

  useEffect(() => {}, [facilityData]);

  const getFacilityDataFromProps = () => {
    const data = [];
    subFacilityData.forEach((e, index) => {
      if (
        index == 0 ||
        (subFacilityData[index - 1] &&
          subFacilityData[index - 1].facilityID != e.facilityID)
      ) {
        data.push({
          facilityID: e.facilityID,
          facilityName: e.facilityName
        });
      }
    });

    setFacilityData(data);
  };

  const handleSubFacilityClick = subFacilityID => {
    if (
      selectedSubFacilityData &&
      selectedSubFacilityData.includes(subFacilityID)
    ) {
      // ID exists, remove it from an array;
      setSelectedSubFacilityData(
        selectedSubFacilityData.filter(x => x !== subFacilityID)
      );
    } else if (
      selectedSubFacilityData &&
      !selectedSubFacilityData.includes(subFacilityID)
    ) {
      // ID not exists, add it to an array;
      setSelectedSubFacilityData(prev => [...prev, subFacilityID]);
    } else {
      //Array is null, add new entry
      setSelectedSubFacilityData([subFacilityID]);
    }
  };
  return (
    <>
      {subFacilityData != null && subFacilityData.length > 0 && (
        <div className="row">
          {facilityData.map((e, index) => {
            return (
              <div
                className="customMapCategoryFacilitySection w-100"
                key={`facilityData_${e.facilityID}`}
              >
                <div className="customMapCategoryFacilitySectionTitle">
                  {e.facilityName}
                </div>
                <div className="customMapCategoryFacilitySectionBody">
                  <div className="row sameColumnsHeightRow">
                    {subFacilityData.map((e2, index2) => {
                      if (e2.facilityID == e.facilityID) {
                        return (
                          <div
                            className="col-sm-12 col-md-6 col-lg-4 col-xl-3"
                            key={`subFacilityData_${e2.subFacilityID}`}
                          >
                            <div
                              className={`customMapCategoryFacilitySectionFacility ${selectedSubFacilityData !=
                                null &&
                                selectedSubFacilityData.includes(
                                  e2.subFacilityID
                                ) &&
                                " customMapCategoryFacilitySectionFacilityChecked"}`}
                              onClick={() =>
                                handleSubFacilityClick(e2.subFacilityID)
                              }
                            >
                              {selectedSubFacilityData != null &&
                                selectedSubFacilityData.includes(
                                  e2.subFacilityID
                                ) && <div className="success-checkmark"></div>}
                              {!selectedSubFacilityData ||
                                (!selectedSubFacilityData.includes(
                                  e2.subFacilityID
                                ) && <div className="default-checkmark"></div>)}
                              <i className="fa fa-hospital"></i>
                              <h3>{e2.subFacilityName}</h3>
                              <p>{e2.shortDescription}</p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            );
          })}

          <div className="col-sm-12 form-group text-right customMapCategoryFacilitySave">
            {canUpdate && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
        </div>
      )}
      {(!subFacilityData || subFacilityData.length == 0) && (
        <div className="row">
          <div className="col-sm-12">
            <span className="text-danger">Facility data not available.</span>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(MapFacility);
