import React, { useState, useMemo, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import { AWS_SDK, variables } from "Variables.js";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { UploadFile, s3DeleteFromURL } from "util/AWSS3Upload";
import { generate_uuidv4 } from "util/CommonFunction";
import { AWSS3BucketKey } from "util/AWSS3BucketKey";
import API, { APIMethod } from "../../../../util/ApiHandler";

const UpdateStaticPage = props => {
  const menuName = "staticpages";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const UpdatedByID = props.authData.userID;

  const history = useHistory();
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState(
    localStorage.getItem("ShortDescription")
  );
  const [id, setID] = useState(null);
  const [description, setValue] = useState(localStorage.getItem("Description"));
  const [bannerImage, setBannerImage] = useState("");
  const [initialBannerImage, setInitialBannerImage] = useState("");
  const [bannerInput, setBannerInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setfile] = useState("");
  const [initialImage, setInitialImage] = useState("");
  const [imageInput, setImageInput] = useState("");
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values
  } = props;

  const key1 = AWSS3BucketKey.staticpages;
  const key2 = AWSS3BucketKey.staticpagesBanner;

  function handleImage(e) {
    if (e.target.files.length > 0) {
      setImageInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setfile(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setImageInput(null);
      setfile(initialImage);
    }
  }

  function handleBannerImage(e) {
    if (e.target.files.length > 0) {
      setBannerInput(e.target.files[0]);

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setBannerImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    } else {
      setBannerInput(null);
      setBannerImage(initialBannerImage);
    }
  }

  useEffect(() => {
    if (!canview) {
      history.push("/staticpages");
    }
    setID(localStorage.getItem("ID"));
    setTitle(localStorage.getItem("Title"));
    setValue(localStorage.getItem("Description"));
    setfile(localStorage.getItem("Image"));
    setInitialImage(localStorage.getItem("Image"));
    setBannerImage(localStorage.getItem("BannerImage"));
    setInitialBannerImage(localStorage.getItem("BannerImage"));
    setShortDescription(localStorage.getItem("ShortDescription"));
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"]
    ]
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };
  const showPreview = () => {
    let { values } = props;
    let pageURL = "/preview";
    localStorage.setItem("PreviewTitle", values.txtTitle);
    localStorage.setItem("PreviewBanner", bannerImage);
    localStorage.setItem("PreviewDescription", description);

    if (title == "About Us") {
      pageURL = "/preview-about-us";
      localStorage.setItem("PreviewShortDescription", shortDescription);
      localStorage.setItem("PreviewImage", image);
    }
    window.open(pageURL, "_blank", "noopener,noreferrer");
  };
  const handleSubmit = async () => {
    let { values, isValid, handleSubmit, submitCount, errors } = props;
    var title = values.txtTitle;
    if (isValid && title) {
      setIsLoading(true);

      let imageLocation = image != "" ? image : null;
      let bannerLocation = bannerImage != "" ? bannerImage : null;

      if (imageInput) {
        const ext = imageInput.name.substring(
          imageInput.name.lastIndexOf(".") + 1
        );
        const uploadKey = key1 + (generate_uuidv4() + "." + ext);
        let bucket = AWS_SDK.bucket;

        //Remove existing file from AWS
        if (
          initialImage &&
          initialImage.includes(`https://${AWS_SDK.bucket}.s3.amazonaws.com/`)
        ) {
          await s3DeleteFromURL(initialImage);
        }
        imageLocation = await UploadFile(imageInput, bucket, uploadKey);
      }

      if (bannerInput) {
        const ext = bannerInput.name.substring(
          bannerInput.name.lastIndexOf(".") + 1
        );
        const uploadKey = key2 + (generate_uuidv4() + "." + ext);
        let bucket = AWS_SDK.bucket;

        //Remove existing file from AWS
        if (
          initialBannerImage &&
          initialBannerImage.includes(
            `https://${AWS_SDK.bucket}.s3.amazonaws.com/`
          )
        ) {
          await s3DeleteFromURL(initialBannerImage);
        }
        bannerLocation = await UploadFile(bannerInput, bucket, uploadKey);
      }

      const endpoint = "StaticPageManagement/" + id;
      const params = {
        title,
        shortDescription,
        description,
        image: imageLocation,
        bannerImage: bannerLocation,
        UpdatedByID
      };
      const { data } = await API(APIMethod.PUT, endpoint, params);
      setIsLoading(false);
      toast.success("Static Page Updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      history.push("/staticpages");
    }
    handleSubmit();
  };

  const cancel = () => {
    history.push("/staticpages");
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Edit
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                <label>Title</label>

                <input
                  id="txtTitle"
                  type="text"
                  value={values.txtTitle}
                  className="form-control react-form-input"
                  onChange={handleChange}
                  placeholder="Enter Blog Title"
                  onBlur={handleBlur}
                  maxLength={50}
                  disabled={true}
                />
                <Error field="txtTitle" />
              </div>
              {title == "About Us" ? (
                <div className="form-group">
                  <label>Short Description</label>
                  <ReactQuill
                    modules={modules}
                    theme="snow"
                    onChange={setShortDescription}
                    value={shortDescription}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="form-group">
                <label>Description</label>

                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={setValue}
                  value={description}
                />
              </div>
              {title === "About Us" && (
                <div className="form-group">
                  <label>Homepage Image</label>
                  <br />
                  <input
                    type="file"
                    id="img"
                    onChange={handleImage}
                    style={{ display: "none" }}
                  />
                  <label
                    className="btn btn-primary"
                    for="img"
                    style={{ color: "white" }}
                  >
                    <i className="fa fa-upload" />{" "}
                    {image ? "Change Image" : "Upload Image"}{" "}
                  </label>
                  {image && image != "" && image != "null" && (
                    <>
                      <br />
                      <br />
                      <img src={image} style={{ height: "200px" }}></img>
                    </>
                  )}
                </div>
              )}

              <div className="form-group">
                <label>Banner Image</label>
                <br />
                <input
                  type="file"
                  id="fuBannerImage"
                  onChange={handleBannerImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuBannerImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {bannerImage && bannerImage != "" && bannerImage != "null"
                    ? "Change Image"
                    : "Upload Image"}{" "}
                </label>
                {bannerImage && bannerImage != "" && bannerImage != "null" && (
                  <>
                    <br />
                    <br />
                    <img src={bannerImage} style={{ height: "200px" }}></img>
                  </>
                )}
              </div>
              <div className="form-group rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={showPreview}
                >
                  Preview
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    txtTitle: Yup.string().required("This Field is required")
  }),
  mapPropsToValues: props => ({
    txtTitle: localStorage.getItem("Title")
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default compose(enhancer, connect(mapStateToProps))(UpdateStaticPage);
