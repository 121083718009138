import React, { useState, useEffect } from "react";

import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import API, { APIMethod } from "../../../../util/ApiHandler";
import CryptoJS from "crypto-js";


const UpdateUser = props => {
  const menuName = "userManagement";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const UpdatedByID = props.authData.userID;
  const [userOption, setuserOption] = useState([]);
  const [userValue, setuserValue] = useState(undefined);
  const userValues = [];
  const userData = [];

  const key = "55a51621a6648525";
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);

  const encryptData = text => {
    const enc = CryptoJS.AES.encrypt(text, keyutf, { iv: iv });
    return enc.toString();
  };

  const decryptData = text => {
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(text) },
      keyutf,
      {
        iv: iv
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    return decStr;
  };

  useEffect(() => {
    if (!canUpdate) {
      history.push("/usermaster");
    }
    if (userOption.length == 0) {
      getUserTypeData();
    }
  });
  const getUserTypeData = async () => {
    const endpoint = "userMaster/getUserTypeData";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.forEach((item, i) => {
      if (item.userTypeID === localStorage.getItem("UserTypeID")) {
        userValues.push({
          value: item.userTypeID,
          label: item.userType
        });
      }
      userData.push({
        value: item.userTypeID,
        label: item.userType
      });
    });
    setuserValue(userValues);
    setuserOption(userData);
  };

  const history = useHistory();

  const [userTypeID, setuserTypeID] = useState(
    localStorage.getItem("UserTypeID")
  );
  const [id, setID] = useState(localStorage.getItem("ID"));
  const [firstName, setFirstName] = useState(localStorage.getItem("FirstName"));
  const [lastName, setLastName] = useState(localStorage.getItem("LastName"));
  const [emailAddress, setEmailAddress] = useState(
    localStorage.getItem("EmailAddress")
  );
  const [password, setPassword] = useState(localStorage.getItem("Password"));
  const [Image, setImage] = useState(localStorage.getItem("Image"));
  const [isActive, setIsActive] = useState(
    localStorage.getItem("IsActive") == "false" ? false : true
  );

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values
  } = props;

  const handleUserTypeChange = selectedOption => {
    setuserValue(selectedOption);
    setuserTypeID(selectedOption.value);
  };

  function handleImage(e) {
    if (e.target.files.length > 0) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = () => {
        setImage(reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };
    }
  }

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = async () => {
    let { values, isValid, handleSubmit } = props;
    var FirstName = values.txtfirstname;
    var LastName = values.txtlastname;
    var Emailaddress = values.txtemailaddress;
    var Password = encryptData(values.txtpassword);
    if (isValid && FirstName && LastName && Emailaddress && Password) {
      const endpoint = "UserMaster/" + id;
      const params = {
        userTypeID,
        FirstName,
        LastName,
        Emailaddress,
        Password,
        image: Image != "" ? Image : null,
        isActive,
        UpdatedByID
      };
      const { data } = await API(APIMethod.PUT, endpoint, params);
      toast.success("User Updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      history.push("/usermaster");
    }
    handleSubmit();
  };

  const cancel = () => {
    history.push("/usermaster");
  };

  const styles = {
    // this one works
    indicatorsContainer: provided => ({
      ...provided,
      background: "lightgray"
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "black" // Custom colour
    })
  };

  return (
    <div className="col-12 ptb-15">
      <div className="roe-card-style">
        <div className="roe-card-header">
          <span className="hash"># </span>Update User
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="form-group">
                {/* <div className="roe-card-description">Category</div> */}
                <label>User Role</label>
                <Select
                  placeholder="Select User Role"
                  value={userValue}
                  onChange={handleUserTypeChange}
                  options={userOption}
                  required={true}
                  styles={styles}
                />
              </div>
              <div className="form-group">
                <label>First Name</label>
                <input
                  id="txtfirstname"
                  type="text"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  placeholder="Enter First Name"
                  onBlur={handleBlur}
                  maxLength={50}
                  value={values.txtfirstname}
                />
                <Error field="txtfirstname" />
              </div>
              <div className="form-group">
                <label>Last Name</label>

                <input
                  id="txtlastname"
                  type="text"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                  onBlur={handleBlur}
                  maxLength={50}
                  value={values.txtlastname}
                />
                <Error field="txtlastname" />
              </div>

              <div className="form-group">
                <label>Email Address</label>

                <input
                  id="txtemailaddress"
                  type="text"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  placeholder="Enter Email Address"
                  onBlur={handleBlur}
                  maxLength={200}
                  value={values.txtemailaddress}
                  disabled={true}
                />
                <Error field="txtemailaddress" />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  id="txtpassword"
                  type="password"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  placeholder="Enter password"
                  onBlur={handleBlur}
                  maxLength={50}
                  value={values.txtpassword}
                />
                <Error field="txtpassword" />
              </div>

              <div className="form-group">
                <label>Image</label>
                <br />
                <input
                  type="file"
                  id="fuImage"
                  onChange={handleImage}
                  style={{ display: "none" }}
                />
                <label
                  className="btn btn-primary"
                  for="fuImage"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-upload" />{" "}
                  {Image ? "Change Image" : "Upload Image"}{" "}
                </label>
                {Image && (
                  <>
                    <br />
                    <br />
                    <img src={Image} style={{ height: "200px" }}></img>
                  </>
                )}
              </div>

              <div className="form-group">
                <div className="pretty p-svg p-curve">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(prev => !prev)}
                  />
                  <div className="state p-success">
                    {/* <!-- svg path --> */}
                    <svg className="svg svg-icon" viewBox="0 0 20 20">
                      <path
                        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                        style={{ stroke: "white", fill: "white" }}
                      ></path>
                    </svg>
                    <label>Active</label>
                  </div>
                </div>
              </div>

              <div className="rightAlignContentDiv">
                {canUpdate && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const enhancer = withFormik({
  validationSchema: Yup.object().shape({
    txtfirstname: Yup.string().required("This Field is required"),
    txtlastname: Yup.string().required("This Field is required"),
    txtemailaddress: Yup.string().required("This Field is required"),
    txtpassword: Yup.string().required("This Field is required")
  }),
  mapPropsToValues: props => ({
    txtfirstname: localStorage.getItem("FirstName"),
    txtlastname: localStorage.getItem("LastName"),
    txtemailaddress: localStorage.getItem("EmailAddress"),
    txtpassword: localStorage.getItem("Password")
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default compose(enhancer, connect(mapStateToProps))(UpdateUser);
