import React from "react";

const PageBanner = ({ pageTitle, bgImage, bgURL }) => {
  const bannerStyle = bgURL && { backgroundImage: `url(${bgURL})` };

  return (
    <div
      className={`page-title-area ${!bgURL && bgImage}`}
      style={{ ...bannerStyle }}
    >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="page-title-item">
            <h2>{pageTitle}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
