import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import classnames from "classnames";
import Pagination from "components/common/Pagination";
import ReactTableWrapper from "../../../../reacttbl.style";
import Button from "components/button/Button";
import { useHistory } from "react-router-dom";
import { AWS_SDK, variables } from "Variables.js";
import SweetAlert from "react-bootstrap-sweetalert";
import RoyTooltip from "components/common/RoyTooltip";
import LoadingSpinner from "../../../../components/loader/LoadingSpinner";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { s3DeleteFromURL } from "util/AWSS3Upload";
import API, { APIMethod } from "../../../../util/ApiHandler";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const HomepageBanner = props => {
  const menuName = "homepage";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;
  const LoggedinUserID = props.authData.userID;

  const history = useHistory();
  const [dummyData, setDummyData] = useState([]);
  const [warningAlert, setWarningAlert] = useState(false);
  const [deleteId, setDeleteID] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!canview) {
      history.push("/dashboard");
    }
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const endpoint = "HomepageBanner/getHomepageBannerData";

    const { data } = await API(APIMethod.GET, endpoint, null);
    data.forEach((el, index) => {
      const regex = /(<([^>]+)>)/gi;
      data[index].description = el.description.replace(regex, "");
      data[index].description =
        data[index].description.length > 50
          ? data[index].description.substr(0, 50) + "..."
          : data[index].description;
    });
    setDummyData(data);
    setIsLoading(false);
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      history.push("/createhomepagebanner");
    }
  };

  const deleteClick = useCallback(
    async BannerData => {
      // Here you can view the data and delete through API calling
      var bannerID = BannerData;
      const endpoint =
        "HomepageBanner/deleteHomePageBanner?id=" +
        bannerID +
        "&userID=" +
        LoggedinUserID;
      const { data } = await API(APIMethod.DELETE, endpoint, null);
      const image = data.bannerImage;
      if (
        image &&
        image.includes(`https://${AWS_SDK.bucket}.s3.amazonaws.com/`)
      ) {
        s3DeleteFromURL(image);
      }

      toast.success("Banner deleted Successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
      getData();
    },
    [dummyData]
  );
  const activeInactiveStatusHandler = async Statusdata => {
    let { bannerID, isActive } = Statusdata;
    const endpoint = "HomepageBanner/UpdatestatusOfBanner";
    const params = {
      bannerID,
      isActive
    };
    const { data } = await API(APIMethod.PUT, endpoint, params);
    toast.success("Status Changed Successfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
    getData();
  };

  const setData = data => {
    let { bannerID, bannerTitle, description, bannerImage, isActive } = data;
    localStorage.setItem("ID", bannerID);
    localStorage.setItem("BannerTitle", bannerTitle);
    localStorage.setItem("Description", description);
    localStorage.setItem("BannerImage", bannerImage ?? "");
    localStorage.setItem("IsActive", isActive);
  };

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Banner Title"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "bannerTitle"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Description"
            />
          );
        },
        Filter: FilterComponent,
        placeholder: "",
        accessor: "description"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Status"
            />
          );
        },
        disableSortBy: true,
        disableFilters: true,
        placeholder: "",
        accessor: "isActive",
        Cell: tableInstance => (
          <div className="react-action-class">
            {tableInstance.row.original.isActive ? (
              canUpdate ? (
                <RoyTooltip
                  id={"active-" + tableInstance.row.original.bannerID}
                  //'active-${tableInstance.row.original.subSubCategoryID}'}
                  title={"Click to Deactivate"}
                  placement="left"
                >
                  <div id={"active-" + tableInstance.row.original.bannerID}>
                    <Button
                      style={{ minWidth: "125px" }}
                      className="c-btn c-info mr-10"
                      onClick={() =>
                        activeInactiveStatusHandler(tableInstance.row.original)
                      }
                    >
                      <div className="fs-14 medium-text">
                        <i className="fas fa-toggle-off mr-6" /> Active
                      </div>
                    </Button>
                  </div>
                </RoyTooltip>
              ) : (
                <div id={"active-" + tableInstance.row.original.bannerID}>
                  <label
                    style={{ minWidth: "125px" }}
                    className="c-btn c-info mr-10"
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </label>
                </div>
              )
            ) : canUpdate ? (
              <RoyTooltip
                id={"deactive-" + tableInstance.row.original.bannerID}
                title={"Click to Active"}
                placement="left"
              >
                <div id={"deactive-" + tableInstance.row.original.bannerID}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-danger mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(tableInstance.row.original)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                  </Button>
                </div>
              </RoyTooltip>
            ) : (
              <div id={"deactive-" + tableInstance.row.original.bannerID}>
                <label
                  style={{ minWidth: "125px" }}
                  className="c-btn c-danger mr-10"
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-on mr-6" /> InActive
                  </div>
                </label>
              </div>
            )}
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class wide-cell">
              {canUpdate && (
                <button
                  className="react-table-edit-button"
                  onClick={() => editClick(tableInstance.row.original)}
                >
                  <i className="fas fa-edit" />
                </button>
              )}
              {candelete && (
                <button
                  className="react-table-delete-button"
                  onClick={() => {
                    setDeleteID(tableInstance.row.original.bannerID);
                    setWarningAlert(!warningAlert);
                  }}
                  //onClick={() => deleteClick(tableInstance.row.original)}
                >
                  <i className="fas fa-trash" />
                </button>
              )}
            </div>
          );
        }
      }
    ],
    [deleteClick]
  );

  const editClick = async bannerData => {
    // Here you can view the data and make forward action for edit data
    const endpoint = "HomepageBanner/" + bannerData.bannerID;
    const { data } = await API(APIMethod.GET, endpoint, null);
    setData(data);
    history.push("/updatehomepagebanner");
  };

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: dummyData,
      columns: columns,
      initialState: {
        hiddenColumns: [!canUpdate && !candelete ? "id" : ""],
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ReactTableWrapper {...props}>
      <div className="roe-card-style mt-15 mb-30">
        <SweetAlert
          warning
          showCancel
          show={warningAlert}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => {
            setConfirmDelete(!confirmDelete);
            setWarningAlert(false);
            deleteClick(deleteId);
          }}
          onCancel={() => setWarningAlert(!warningAlert)}
        ></SweetAlert>

        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Homepage Banner
          </div>

          <div className="mr-10">
            {canAdd && (
              <Button
                className="c-btn ma-5 c-primary"
                onClick={() => formAction("add")}
              >
                <i className="fas fa-plus mr-10" />
                Add Banner
              </Button>
            )}
          </div>
        </div>

        <div className="table-container  overflow-auto">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => {
                    return (
                      <td>
                        <div>
                          {header.canFilter ? header.render("Filter") : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          onPageChange={gotoPage}
          pages={pageCount}
          page={pageIndex}
        />
      </div>
    </ReactTableWrapper>
  );
};

const FilterComponent = tableInstance => {
  const { filterValue, setFilter } = tableInstance.column;
  return (
    <input
      type="text"
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="tabl-search react-form-input"
      placeholder={`Search ${tableInstance.column.placeholder}`}
      onClick={e => e.stopPropagation()}
    />
  );
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(HomepageBanner);
