import React, { useState, useEffect } from "react";
import LatestActivityWrapper from "./latestactivity.style";
import { people1, people2, people3, people4 } from "./../../../helper/constant";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";

const LatestActivity = ({ type, carddata }) => {
  const [dummyData, setDummyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDummyData(carddata);
  }, [carddata]);

  return (
    <LatestActivityWrapper className="fill-height">
      <div className="card roe-shadow-2 fill-height">
        {isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <div className="card-body">
            <div className="flex-x align-center">
              <h4 className="mb-0 fs-25 header">
                {type == "blogs" ? "Latest blogs" : "Latest News"}{" "}
              </h4>
              <div className="ml-auto nevy--text fs-15 demi-bold-text">
                <a href={type === "blogs" ? "/blog" : "/News"}>View more</a>
              </div>
            </div>
            <br />
            {/* <div className="flex-x ptb-15 border-bottom mt-9"> */}
            {dummyData &&
              dummyData.map((e, index) => {
                return (
                  <div className="flex-x ptb-15 border-bottom mt-9">
                    <div>
                      <img
                        className="img-sm"
                        src={
                          e.image
                            ? e.image
                            : type == "blogs"
                            ? "/images/default-blog.jpg"
                            : "images/default-news.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="mlr-10 flex-1">
                      <p className="mb-1 fs-15 demi-bold-text">{e.title}</p>
                    </div>
                  </div>
                );
              })}

            {/* </div> */}
          </div>
        )}
      </div>
    </LatestActivityWrapper>
  );
};

export default LatestActivity;
