import React, { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import PageBanner from "./PageBanner";
import TopHeader from "./TopHeader";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "assets/scss/preview-page.scss";

const Preview = props => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [bannerImage, setBannerImage] = useState();

  useEffect(() => {
    setTitle(localStorage.getItem("PreviewTitle"));
    setDescription(localStorage.getItem("PreviewDescription"));
    setBannerImage(localStorage.getItem("PreviewBanner"));

    localStorage.removeItem("PreviewTitle");
    localStorage.removeItem("PreviewDescription");
    localStorage.removeItem("PreviewBanner");
  }, []);

  return (
    <>
      <TopHeader />
      <Navbar />

      <div style={{ height: "100vh", overflow: "auto" }}>
        <PageBanner
          pageTitle={
            title ? title : "" // "Service"
          }
          bgImage="page-title-one"
          bgURL={
            bannerImage && bannerImage != "null" && bannerImage != ""
              ? bannerImage
              : null
          }
        />

        <div className="service-details-area ptb-100 custom-service-details">
          <div className="container">
            {!description && (
              <div className="text-center">
                <h2>Information not available at the moment.</h2>
              </div>
            )}
            {description && (
              <div className="dynamic-Content">
                {HTMLReactParser(description)}
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Preview;
