export const variables = {
  API_URL: process.env.REACT_APP_API_ENDPOINT
  // API_URL: "https://localhost:44301/"
  // API_URL: "https://api.addictionandmentalhealthtreatment.com/"
};

export const AWS_SDK = {
  accessKeyId:
    process.env.NODE_ENV === "production"
      ? "AKIA4QRSWPP7PGATTE3Y"
      : "AKIA4QRSWPP7MJX7257M",
  secretAccessKey:
    process.env.NODE_ENV === "production"
      ? "JaW03s3XXtYIjd+gfWjgUWA/cDf27berb4u04ZF/"
      : "JUNUaukM9adPHgQ5yzabUf42gwPhTb+lJoxtVjeQ",
  region: process.env.NODE_ENV === "production" ? "us-east-1" : "us-east-1",
  bucket:
    process.env.NODE_ENV === "production"
      ? "praesum-healthcare-production"
      : "praesumhealthcare-test"
};
