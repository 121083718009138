import React, { useState, useEffect } from "react";
import { loginBack, ForgotIcon } from "helper/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../util/ApiHandler";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";

const ChangePassword = props => {
  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const history = useHistory();
  const LoggedinUser = props.authData.userID;

  const [password, setpassword] = useState();
  const [confirmpassword, setConfiempassword] = useState();
  const [userID, setuserID] = useState();

  const key = "55a51621a6648525";
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  useEffect(() => {
    if (LoggedinUser) {
      setuserID(LoggedinUser);
    } else {
      history.push("/Login");
    }
  }, [LoggedinUser]);

  const encryptData = text => {
    const enc = CryptoJS.AES.encrypt(text, keyutf, { iv: iv });
    return enc.toString();
  };

  const handleResetPassword = async e => {
    e.preventDefault();
    if (password != confirmpassword) {
      toast.error("Password and Confirm password are not same.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    var encPassword = encryptData(password);
    const endpoint = "UserMaster/changePassword";
    const params = {
      userID: LoggedinUser,
      password: encPassword
    };
    const { data } = await API(APIMethod.PUT, endpoint, params);
    toast.success("password changed sucessfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
    history.push("/Login");
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Reset Password</div>

        <form className="pa-24" onSubmit={handleResetPassword}>
          <div className="form-group">
            <input
              type="password"
              className="form-control react-form-input"
              id="exampleInputpassword"
              placeholder="Enter password"
              onChange={e => setpassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control react-form-input"
              id="exampleInputConfirmpassword"
              placeholder="Confirm password"
              onChange={e => setConfiempassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn form-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(ChangePassword);
