import SS3 from "aws-sdk/clients/s3";
import { AWS_SDK } from "Variables";

var s3 = new SS3({
  accessKeyId: AWS_SDK.accessKeyId,
  secretAccessKey: AWS_SDK.secretAccessKey,
  region: AWS_SDK.region
});

export function s3Upload(params) {
  return new Promise((resolve, reject) => {
    s3.upload(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function s3DeleteFromURL(url) {
  const objectKey = url.replace(
    `https://${AWS_SDK.bucket}.s3.amazonaws.com/`,
    ""
  );
  const params = {
    Bucket: AWS_SDK.bucket,
    Key: objectKey
  };
  return new Promise((resolve, reject) => {
    s3.deleteObject(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export const UploadFile = async (fileInput, bucket, uploadKey) => {
  let url = await s3Upload({
    Body: fileInput,
    Bucket: bucket,
    Key: uploadKey,
    ACL: "public-read",
    ContentType: fileInput.type
  });

  return url.Location;
};
