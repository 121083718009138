import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { FormGroup, Input, Label } from "reactstrap";
import { CategoryType } from "helper/constant";
import Select from "react-select";
import MapFacility from "components/apppages/CategoryMaster/MapFacility";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import API, { APIMethod } from "../../../../util/ApiHandler";

const MapCategoryFacility = props => {
  const menuName = "categories";
  const permissions = props.authData.permission.filter(
    v => v.menuName === menuName
  )[0];
  const canview = permissions ? permissions.canView : false;
  const canAdd = permissions ? permissions.canAdd : false;
  const canUpdate = permissions ? permissions.canUpdate : false;
  const candelete = permissions ? permissions.canDelete : false;

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [subFacilityData, setSubFacilityData] = useState([]);
  const [selectedSubFacilityData, setSelectedSubFacilityData] = useState(null);

  const [serviceType, setServiceType] = useState(null);
  const [serviceTypeName, setServiceTypeName] = useState(null);

  const [categorySelectedValue, setCategorySelectedValue] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [referenceID, setReferenceID] = useState(null);

  useEffect(() => {
    if (!canUpdate) {
      history.push("/dashboard");
    }
    GetSubFacilityData();
  }, []);

  //Trigger when radio button/type changed
  useEffect(() => {
    setServiceTypeName(null);
    setCategorySelectedValue([]);
    setCategoryOptions([]);
    setReferenceID(null);

    if (serviceType) {
      if (serviceType == CategoryType.Category) {
        setServiceTypeName("Category");
        GetCategoryData();
      } else if (serviceType == CategoryType.SubCategory) {
        setServiceTypeName("Sub-Category");
        GetSubCategoryData();
      } else if (serviceType == CategoryType.SubSubCategory) {
        setServiceTypeName("Sub-Sub-Category");
        GetSubSubCategoryData();
      }
    }
  }, [serviceType]);

  //Trigger when dropdown/category changed
  useEffect(() => {
    setSelectedSubFacilityData(null);
    if (referenceID) {
      GetMappingData(serviceType, referenceID);
    }
  }, [referenceID]);

  //Get all sub facilities without any filters
  const GetSubFacilityData = async () => {
    setSubFacilityData([]);
    setIsLoading(true);
    const endpoint = "MapCategoryFacility/getSubFacilityDataForMapping";
    const { data } = await API(APIMethod.GET, endpoint, null);
    setSubFacilityData(data);
    setIsLoading(false);
  };

  //Get data for Dropdown
  const GetCategoryData = async () => {
    const CategoryData = [];
    setIsLoading(true);
    const endpoint = "CategoryMaster/getCategoryDataForDDL";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.map(e => {
      CategoryData.push({
        value: e.categoryID,
        label: e.categoryName
      });
    });

    setIsLoading(false);
    setCategoryOptions(CategoryData);
  };
  const GetSubCategoryData = async () => {
    const CategoryData = [];
    setIsLoading(true);
    const endpoint = "SubCategoryMaster/getSubCategoryDataForDDL";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.map(e => {
      CategoryData.push({
        value: e.subCategoryID,
        label: `${e.subCategoryName} (${e.categoryName})`
      });
    });

    setIsLoading(false);
    setCategoryOptions(CategoryData);
  };
  const GetSubSubCategoryData = async () => {
    const CategoryData = [];
    setIsLoading(true);
    const endpoint = "SubSubCategoryMaster/getSubSubCategoryDataForDDL";
    const { data } = await API(APIMethod.GET, endpoint, null);
    data.map(e => {
      CategoryData.push({
        value: e.subSubCategoryID,
        label: `${e.subSubCategoryName} (${e.subCategoryName})`
      });
    });

    setIsLoading(false);
    setCategoryOptions(CategoryData);
  };

  const handleCategoryChange = selectedOption => {
    setCategorySelectedValue(selectedOption);
    setReferenceID(selectedOption.value);
  };

  //Get list of facilities that mapped with the selected reference
  const GetMappingData = async (serviceType, referenceID) => {
    setSelectedSubFacilityData(null);
    setIsLoading(true);
    const endpoint = `MapCategoryFacility/getMappingDataForEdit?referenceTypeID=${serviceType}&referenceID=${referenceID}`;
    const { data } = await API(APIMethod.GET, endpoint, null);
    if (data && data.length > 0) {
      let datas = data[0];
      setSelectedSubFacilityData(datas.subFacilities);
    } else {
      setSelectedSubFacilityData([]);
    }

    setIsLoading(false);
  };

  const handleSubmit = async () => {
    if (!serviceType) {
      toast.warning("Select Type.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!referenceID) {
      toast.warning(`Select ${serviceTypeName}.`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    const params = {
      referenceTypeID: parseInt(serviceType),
      referenceID,
      subFacilities: selectedSubFacilityData,
      isActive: true
    };

    setIsLoading(true);
    const endpoint = "MapCategoryFacility/manageMapping";
    const { data } = await API(APIMethod.POST, endpoint, params);
    toast.success("Mapping Saved Successfully.", {
      position: toast.POSITION.TOP_RIGHT
    });
    setServiceType(null);

    setIsLoading(false);
  };

  const styles = {
    // this one works
    indicatorsContainer: provided => ({
      ...provided,
      background: "lightgray"
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "black" // Custom colour
    })
  };

  const renderComponent = (
    <>
      <div className="roe-card-style mt-15 mb-30">
        <div className="roe-card-header module-header">
          <div className="flex-1">
            <span className="hash"># </span> Map Category & Facilities
          </div>
        </div>
        <div className="roe-card-body">
          <div>
            <form>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <label className="w-100">Select Type</label>
                  <FormGroup className="d-inline-block" check>
                    <Label check>
                      <Input
                        type="radio"
                        value={CategoryType.Category}
                        name="rbServiceType"
                        onChange={e => setServiceType(e.target.value)}
                        checked={serviceType == CategoryType.Category}
                      />
                      Category
                    </Label>
                  </FormGroup>
                  <FormGroup
                    className="d-inline-block"
                    style={{ marginLeft: "0.5rem" }}
                    check
                  >
                    <Label check>
                      <Input
                        type="radio"
                        value={CategoryType.SubCategory}
                        name="rbServiceType"
                        onChange={e => setServiceType(e.target.value)}
                        checked={serviceType == CategoryType.SubCategory}
                      />
                      Sub-Category
                    </Label>
                  </FormGroup>
                  <FormGroup
                    className="d-inline-block"
                    style={{ marginLeft: "0.5rem" }}
                    check
                  >
                    <Label check>
                      <Input
                        type="radio"
                        value={CategoryType.SubSubCategory}
                        name="rbServiceType"
                        onChange={e => setServiceType(e.target.value)}
                        checked={serviceType == CategoryType.SubSubCategory}
                      />
                      Sub-Sub-Category
                    </Label>
                  </FormGroup>
                </div>

                {serviceType && (
                  <div className="col-sm-12 form-group">
                    <label>{serviceTypeName}</label>
                    <Select
                      placeholder={`Select ` + serviceTypeName}
                      value={categorySelectedValue}
                      onChange={handleCategoryChange}
                      options={categoryOptions}
                      required={true}
                      styles={styles}
                    />
                  </div>
                )}
              </div>

              {serviceType != null && referenceID != null && (
                // && selectedSubFacilityData != null
                <MapFacility
                  subFacilityData={subFacilityData}
                  selectedSubFacilityData={selectedSubFacilityData}
                  setSelectedSubFacilityData={setSelectedSubFacilityData}
                  handleSubmit={handleSubmit}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
  return isLoading ? <LoadingSpinner /> : renderComponent;
};
const mapStateToProps = state => {
  return {
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
      userID: state.auth.userID,
      permission: state.auth.permission
    }
  };
};

export default connect(mapStateToProps)(MapCategoryFacility);
